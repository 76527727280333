@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
@import '../../../styles/buttons';
.InfoBar {
	@include no-select();
	@include flex('space-between', 'center', 'row');
	position: relative;
	height: 3.9em;
	min-width: 13.6em;
	width: fit-content;
	padding: 0.66em;
	background-color: $white;
	border-radius: 0.55em;
	@include box-shadow(0, 0.16em, 0.4em, 0, rgba($black, 0.2));

	.InfoBar-icon {
		background-size: contain;
		width: 3.3em;
		height: 3.3em;
		margin-right: 1em;
		&.trophy {
			background-image: url(../../../assets/images/icons/icon-trophy.svg);
		}

		&.person {
			background-image: url(../../../assets/images/icons/icon-group.svg);
			width: 2.1em;
			margin-left: 0.6em;

			&.game2 {
				background-image: url(../../../assets/images/icons/icon-group2.svg);
			}
		}
	}
	.InfoBar-text {
		@include flex('flex-end', 'center');
		min-width: 7.8em;
		height: 2.8em;
		background-color: $blueWhite;
		border-radius: 0.3em;
		padding-right: 0.9em;
		padding-left: 0.9em;
		
		&.game2 {
			background-color: $beige;
		}

		span {
			font-size: 1.3em;
			font-weight: bold;
			color: $text-dark;
		}

		&.isPoints {
			span {
				font-size: 2.1em;
				font-weight: 900;
			}
		}
	}
}