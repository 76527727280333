@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
@import '../../styles/buttons';
.LoginGroup {
	width: 100%;
	height: 100%;
	margin: 0 auto;

	.LoginGroup-title {
		width: 100%;
		height: 5em;
		@include flex('flex-start', 'center');
		padding: 0 3.39em;

		background-color: $blueLight;
		border-top-left-radius: 0.5em;
		border-top-right-radius: 0.5em;
		span {
			font-size: 1.67em;
			font-weight: 900;
			color: $darkBlue;
			text-transform: uppercase;
		}
	}
	.LoginGroup-body {
		width: 100%;
		height: calc(100% - 5em);
		background-color: white;
		border-top: 0.12em solid $cyan;
		border-bottom-left-radius: 0.5em;
		border-bottom-right-radius: 0.5em;
		padding: 1.45em  3.22em 0 3.22em;

		.LoginGroup-text {
			// margin-bottom: 2.8em;
			@include no-select();
			height: 2.34em;
			span {
				font-size: 1.33em;
				color: $darkBlue;
				font-weight: 500;
			}
		}
		.LoginGroup-form {
			position: relative;
			input {
				display: block;
				font-size: 1.17em;
				line-height: 1;
				width: 100%;
				height: 2.19em;
				padding: 0.66em;
				margin: auto;
				margin-bottom: 1.16em;
				background-color: $blueLight;
				border-radius: 0.44em;
				border-width: 0.11em;
				border-style: solid;
				border-color: $cyan;
				color: $darkBlue;
				font-weight: normal;
				&::placeholder {
					color: rgba($text-dark, 0.5);
				}
			}
			.LoginGroup-buttons {
				@include flex('space-between', 'center');
				> div { 
					margin: 0 0.5em;
					&:nth-child(1) {margin-left: 0;}
					&:nth-last-child(1) {margin-right: 0;}
				}			
				
			}
			.LoginGroup-loginBtn {
				width: 100%;
			}
			.LoginGroup-createBtn {
				margin-bottom: 1em;
			}
			
			.LoginGroup-errorMessage {
				height: 1.5em;
				margin-top: 1em;
				margin-bottom: 0.6em;
				padding: 0 1em;
				font-size: 1.11em;
				color: $darkBlue;
				text-align: center;
			}
			.LoginGroup-intructions {
				height: 2.86em;
				width: 100%;
				margin-top: 5em;
				text-align: center;
				span {
					font-size: 1.17em;
					color: $darkBlue;
				}
			}
		}	
	}

	.LoginGroup-groups {
		margin-top: 1em;
		height: 14.5em;
		overflow: auto;
		.LoginGroup-groupBtn {			
			margin-bottom: 0.5em;
			color: white;
			background-color: $darkBlue;
			cursor: pointer;
			span {
				font-size: 1.5em;
			}
		
		}
	}

}