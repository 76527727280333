@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
@import '../../styles/buttons';
.SelectGame {
	position: relative;
	width: 100%;
	height: 100%;

	.SelectGame-background {
		position: absolute;
		height: 100%;
		width: 100%;
		@include flex('', '', 'column');

		.SelectGame-backgroundTop {
			position: relative;
			height: 100%;
			background-image: url(../../assets/images/backgrounds/goodsellers/login.png);
			background-size: cover;
			background-position: center top;
		}
		.SelectGame-backgroundDivider {
			height: 1.1em;
			background-color: $darkBlue;
		}
		.SelectGame-backgroundBottom {
			position: relative;
			height: 100%;
			background-image: url(../../assets/images/backgrounds/novo-medical/login.png);
			background-size: cover;
			background-position: center bottom;
		}
		.SelectGame-backgroundOverlay {
			position: absolute;
			height: 100%;
			width: 100%;
			background-image: url(../../assets/images/backgrounds/background-overlay.svg);
			background-size: cover;
		}
	}

	.SelectGame-games{
		position: absolute;
		width: 100%;
		height: 100%;
		@include flex('space-around', 'center', 'column');
		
		.SelectGame-game {
			width: 28.5em;
			height: 4.6em;
			background-size: 28.5em;
			cursor:pointer;
			@include transition(0.1s);
			&.game1 {
				background-image: url('../../assets/images/logo-game1.svg');
			}
			&.game2 {
				background-image: url('../../assets/images/logo-game2.svg');
			}
			&:hover {
				width: 30em;
				background-size: 30em;
			}
		}	
	}

	.SelectGame-adminBtn {
		position: absolute;
		left: 1em;
		bottom: 1em;
	}
}