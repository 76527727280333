@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
@import '../../styles/buttons';
.Game {
	position: relative;
	width: 100%;
	height: 100%;

	.Game-backBtn {
		position: absolute;
		left: 2.8em;
		bottom: 2em;
		width: 14.44em;
	}
}