@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
@import '../../../styles/buttons';
.Logo {
	background-size: contain;
	background-image: url('../../../assets/images/logo-game1.svg');
	width: 22.75em;
	height: 5em;
	&.game1 {
		background-image: url('../../../assets/images/logo-game1.svg');
		width: 22.75em;
		height: 5em;
		&.landing {
			width: 34.33em;
			height: 5.33em;
		}
	}
	&.game2 {
		background-image: url('../../../assets/images/logo-game2.svg');
		width: 22.75em;
		height: 5em;
		&.landing {
			width: 34.33em;
			height: 5.33em;
		}
	}
}