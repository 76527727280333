@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
@import '../../styles/buttons';
.NoAccessPage {
	height: 100%;
	width: 100%;
	padding-top: 1.82em;
	@include flex('flex-start', 'center', 'column');
	.NoAccessPage-logoWrapper {
		position: relative;
	}

	
	.NoAccessPage-content {
		position: relative;
		width: 60em;
		background-color: white;
		border-radius: 0.56em;
		padding: 1.7em 1.8em 1.8em 1.8em;
		margin-top: 7em;
		@include flex('', 'center', 'column');
		@include box-shadow(0, 0.15em, 0.67em, 0, rgba($black, 0.2));

		p {
			color: $darkBlue;
			b {
				font-weight: 500;
			}
		}
	}
	
	.NoAccessPage-logoutBtn {
		position: absolute;
		top: 6em;
		left: 1em;
	}
	.NoAccessPage-adminBtn {
		position: absolute;
		top: 9.75em;
		left: 1em;
	}
	.NoAccessPage-backBtn {
		position: absolute;
		width: 10em;
		bottom: 1em;
		left: 1em;
	}
}
