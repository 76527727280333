@mixin noAnimation() {
  -webkit-animation: none;
  animation: none;
}

@mixin fontBlink($seconds: '2s', $iterationCount: infinite, $animationFillMode: forwards) {
  animation: fontBlink $seconds $iterationCount $animationFillMode;
  -webkit-animation: fontBlink $seconds $iterationCount $animationFillMode;
}
@-webkit-keyframes fontBlink {
  0% {
    @include scale(1);
  }
  50% {
    @include scale(1.25);
  }
  100% {
    @include scale(1);
  }
}
@keyframes fontBlink {
  0% {
    @include scale(1);
  }
  50% {
    @include scale(1.25);
  }
  100% {
    @include scale(1);
  }
}

@mixin animateCorrect() {
  -webkit-animation: bounce 0.5s cubic-bezier(0.280, 0.840, 0.420, 1) 0s 1 forwards;
  -webkit-animation-fill-mode: forwards;
  animation: bounce 0.5s cubic-bezier(0.280, 0.840, 0.420, 1) 0s 1 forwards;
  animation-fill-mode: forwards;
}

@-webkit-keyframes bounce {
	from {
    @include scale(1); 
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
	10% {@include scale(0.9);}
	30% {@include scale(1.05);}
	60% {@include scale(0.95);}
	to {
    @include scale(1); 
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes bounce {
	from {
    @include scale(1); 
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
	10% {@include scale(0.9);}
	30% {@include scale(1.05);}
	60% {@include scale(0.95);}
	to {
    @include scale(1); 
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@mixin fadeIn($seconds: 0.5s, $timing: 'ease', $delay: 0s) {
  @include opacity(0);
  animation: fadeIn $seconds #{$timing} $delay forwards;
  -webkit-animation: fadeIn $seconds #{$timing} $delay forwards;
}

@-webkit-keyframes fadeIn {
	0% {@include opacity(0);}
	100% {@include opacity(1);}
}
@keyframes fadeIn {
	0% {@include opacity(0);}
	100% {@include opacity(1);}
}

@mixin animateWrong() {
  -webkit-animation: wobble 0.5s cubic-bezier(0.280, 0.840, 0.420, 1) 0s 1 forwards;
  -webkit-animation-fill-mode: forwards;
  animation: wobble 0.5s cubic-bezier(0.280, 0.840, 0.420, 1) 0s 1 forwards;
  animation-fill-mode: forwards;
}

@-webkit-keyframes wobble {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  15% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -3deg);
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -3deg);
  }

  30% {
    -webkit-transform: translate3d(4%, 0, 0) rotate3d(0, 0, 1, 2deg);
    transform: translate3d(4%, 0, 0) rotate3d(0, 0, 1, 2deg);
  }

  45% {
    -webkit-transform: translate3d(-3%, 0, 0) rotate3d(0, 0, 1, -2deg);
    transform: translate3d(-3%, 0, 0) rotate3d(0, 0, 1, -2deg);
  }

  60% {
    -webkit-transform: translate3d(3%, 0, 0) rotate3d(0, 0, 1, 1deg);
    transform: translate3d(3%, 0, 0) rotate3d(0, 0, 1, 1deg);
  }

  75% {
    -webkit-transform: translate3d(-2%, 0, 0) rotate3d(0, 0, 1, -1deg);
    transform: translate3d(-2%, 0, 0) rotate3d(0, 0, 1, -1deg);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes wobble {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  15% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -3deg);
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -3deg);
  }

  30% {
    -webkit-transform: translate3d(4%, 0, 0) rotate3d(0, 0, 1, 2deg);
    transform: translate3d(4%, 0, 0) rotate3d(0, 0, 1, 2deg);
  }

  45% {
    -webkit-transform: translate3d(-3%, 0, 0) rotate3d(0, 0, 1, -2deg);
    transform: translate3d(-3%, 0, 0) rotate3d(0, 0, 1, -2deg);
  }

  60% {
    -webkit-transform: translate3d(3%, 0, 0) rotate3d(0, 0, 1, 1deg);
    transform: translate3d(3%, 0, 0) rotate3d(0, 0, 1, 1deg);
  }

  75% {
    -webkit-transform: translate3d(-2%, 0, 0) rotate3d(0, 0, 1, -1deg);
    transform: translate3d(-2%, 0, 0) rotate3d(0, 0, 1, -1deg);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes floatRight {
	0% {-webkit-transform: translateX(-35%);}
	100% {-webkit-transform: translateX(120%);}
}

@keyframes floatRight {
	0% {transform: translateX(-20%);}
	100% {transform: translateX(100%);}
}

@mixin moveFromPoint($seconds: 1s, $delay: 0s, $top: 0em, $left: 0em) {
  $animation-name: unique-id() !global;

  @-webkit-keyframes #{$animation-name} {
    0% {
      top: $top;
      left: $left;
      @include scale(0);
      @include opacity(1);
    }
    100% {
      @include scale(1);
      @include opacity(1);
    }
  }
  
  @keyframes #{$animation-name} {
    0% {
      top: $top;
      left: $left;
      @include scale(0);
      @include opacity(1);
    }
    100% {
      @include scale(1);
      @include opacity(1);
    }
  }

  -webkit-animation: $animation-name $seconds ease-out $delay 1 forwards;
  animation: $animation-name $seconds ease-out $delay 1 forwards;
}

@mixin growWidth($seconds: 1s, $delay: 0s, $finalWidth: 20em) {
  $animation-name: unique-id() !global;

  @-webkit-keyframes #{$animation-name} {
    0% {
      width: 0;
    }
    100% {
      width: $finalWidth
    }
  }
  
  @keyframes #{$animation-name} {
    0% {
      width: 0;
    }
    100% {
      width: $finalWidth
    }
  }

  -webkit-animation: $animation-name $seconds ease-out $delay 1 forwards;
  animation: $animation-name $seconds ease-out $delay 1 forwards;
}

@mixin colorBlink($seconds: 1s, $delay: 0s, $iterations: 1, $initialColor, $color) {
  $animation-name: unique-id() !global;

  @-webkit-keyframes #{$animation-name} {
    0% {
      background-color: $initialColor;
    }
    50% {
      background-color: $color;
    }
    100% {
      background-color: $initialColor;
    }
  }
  
  @keyframes #{$animation-name} {
    0% {
      background-color: $initialColor;
      color: $text-dark !important;
    }
    50% {
      background-color: $color;
      color: $text-light !important;
    }
    100% {
      background-color: $initialColor;
      color: $text-dark !important;
    }
  }

  -webkit-animation: $animation-name $seconds ease-out $delay $iterations;
  animation: $animation-name $seconds ease-out $delay $iterations;
}

@mixin textColorBlink($seconds: 1s, $delay: 0s, $iterations: 1, $initialColor, $color) {
  $animation-name: unique-id() !global;

  @-webkit-keyframes #{$animation-name} {
    0% {
      color: $initialColor;
    }
    50% {
      color: $color;
    }
    100% {
      color: $initialColor;
    }
  }
  
  @keyframes #{$animation-name} {
    0% {
      color: $initialColor;
    }
    50% {
      color: $color;
    }
    100% {
      color: $initialColor;
    }
  }

  -webkit-animation: $animation-name $seconds ease-out $delay $iterations;
  animation: $animation-name $seconds ease-out $delay $iterations;
}

@mixin scaleBounce($seconds: 1s, $delay: 0s, $iterations: 1) {
  -webkit-animation: scaleBounce $seconds ease-out $delay $iterations;
  animation: scaleBounce $seconds ease-out $delay $iterations;

  @-webkit-keyframes scaleBounce {
    0% {
      @include scale(1);
    }
    50% {
      @include scale(0.5);
    }
    100% {
      @include scale(1);
    }
  }
  
  @keyframes scaleBounce {
    0% {
      @include scale(1);
    }
    50% {
      @include scale(1.5);
    }
    100% {
      @include scale(1);
    }
  }
}