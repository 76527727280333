@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
@import '../../../styles/buttons';
.GroupName {
	position: relative;
	width: 100%;
	height: 100%;
	pointer-events: all;
	@include flex('flex-start', 'center', 'row');
	span {
		color: $darkBlue;
		font-size: 1.33em;
		font-weight: 700;
		white-space: nowrap;
		overflow: hidden;
	}
	input {
		display: inline-block;
		color: $darkBlue;
		font-size: 1.33em;
		font-weight: 700;
		padding: 0;
		height: calc(100% - 0.5em);
		width: calc(100% - 2.25em);
		border-radius: 0.2em;
		background-color: rgba(white, 0.7);
		&:focus {
			font-size: 1.33em;
		}
	}

	.GroupName-editBtn,
	.GroupName-saveBtn {
		display: none;
		position: absolute;
		right: 0.25em;
		top: 0;
		width: 2.5em;
		height: 100%;
		background-size: auto 2em;
		background-position: center center;
		cursor: pointer;
		&:hover {
			background-size: auto 2.1em;
		}
	}
	.GroupName-editBtn {
		background-image: url('../../../assets/images/icons/icon-edit.svg');
	}
	.GroupName-saveBtn {
		background-image: url('../../../assets/images/icons/icon-save.svg');
	}

	&.ready {
		padding-left: 0.84em;
	}

	&.leaderboard {
		width: 43em;
		padding-left: 1.18em;
	}

	&:hover {
		&:not(.editing) {
			.GroupName-editBtn {
				display: block;
			}
		}
	}

	&.editing {
		.GroupName-saveBtn {
			display: block;
		}
	}
	&.saving {
		.GroupName-saveBtn {
			cursor: default;
			background-image: url('../../../assets/images/icons/icon-loading.svg');
		}
	}
}
