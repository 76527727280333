@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
@import '../../../styles/buttons';
.Sort {
	position: relative;
	width: 100%;
	height: 100%;
	padding: 1.67em 2.44em;
	overflow: auto;
	scroll-behavior: smooth;
	-webkit-overflow-scrolling: touch;
	@include hide-scrollbar();

	.Sort-intro {
		margin-bottom: 1.67em;
		p {
			color: $darkBlue;
			font-size: 1.17em;
			line-height: 1.61;
			margin-bottom: 0;
			&:first-of-type {margin-top: 0;}
		}
	}

	.Sort-content {
		width: 100%;
		@include flex('space-between', 'flex-start');
		.Sort-categories {
			width: 25.67em;
			.Sort-category {
				&:not(.image) {
					margin-bottom: 0.78em;
					padding: 0.4em;
					border-radius: 0.44em;
					background-color: $blue;

					&.game2 {
						background-color: $purpleLight;
					}

					.Sort-categoryTitle {
						padding: 0 0.6em 0.4em 0.6em;
						height: 2em;
						color: $white;
						@include flex('flex-start', 'center');
						p {
							text-transform: uppercase;
							font-weight: 700;
						}
					}
				}
				
			}
		}
		.Sort-items {
			width: 23.89em;
		}
	}


	&.prep-extinguishers {
		.Sort-content {
			display: block;
			.Sort-categories {
				width: 100%;
				height: 15.33em;
				margin-bottom: 1.4em;
				@include flex('space-between', 'center');
				.Sort-category {
					width: 16.61em;
					height: 100%;
				}
			}
			.Sort-items {
				width: 100%;
				height: 14.2em;
				background-color: $blueLight;
				border: 1px solid $blueGrey;
				border-radius: 0.56em;
				padding: 0 0.8em;
				@include flex('space-between', 'center');
				.Sort-itemGhost {
					width: 12.56em;
					height: 12.56em;
					background-color: $blueGrey;
					border-radius: 0.56em;
				}
			}
		}
	}

	&.prep-shelves {
		.Sort-content {
			@include flex('','','row-reverse');
			.Sort-categories {
				height: 29.5em;
				width: 24.1em;
				margin-left: 1.5em;
				@include flex('space-between', 'center', 'column');
				padding-top: 2em;
				padding-bottom: 1.25em;
				background-image: url('../../../assets/images/tasks/sort/prep-shelves/shelves.svg');
				.Sort-category {
					width: 20em;
					height: 7.25em;
				}
			}
			.Sort-items {
				width: 32em;
				min-height: 1.2em;
				margin-top: 2em;
				background-color: $blueLight;
				border: 1px solid $blueGrey;
				border-radius: 0.56em;
				padding: 0 0.8em;
				@include flex('space-between', 'center', 'row');
				flex-wrap: wrap;
				.Sort-itemGhost {
					margin: 0.5em;
					width: 8.6em;
					height: 8.6em;
					background-color: $blueGrey;
					border-radius: 0.56em;
				}
			}
		}
	}

	&.sales-shop-window {
		.Sort-content {
			display: block;
			@include flex('space-between', 'flex-start', 'row-reverse');
			.Sort-categories {
				width: 23.85em;
				height: 29.82em;
				padding-top: 1.73em;
				background-image: url('../../../assets/images/tasks/sort/sales-shop-window/background-window.svg');
				.Sort-category {
					width: 100%;
					height: 8.8em;
					&:last-of-type {
						height: 10em;
					}
				}
			}
			.Sort-items {
				width: 29.82em;
				height: 29.82em;
				background-color: $blueLight;
				border: 1px solid $blueGrey;
				border-radius: 0.56em;
				padding: 0 0.8em;
				@include flex('space-between', 'center');
				flex-flow: row wrap;
				.Sort-itemGhost {
					width: 8.67em;
					height: 8.67em;
					background-color: $blueGrey;
					border-radius: 0.56em;
				}
			}
		}
	}
}