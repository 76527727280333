@mixin button-purple() {
	color: $white;
	background-color: $purpleLight;
	border-bottom: 0.44em solid $purpleDark;
	@include box-shadow(0, 0.17em, 0.44em, 0, rgba($blueShadow, 0.4));
	&:hover,
	&:focus,
	&:active {
		background-color: $purple;
		border-bottom-color: $purpleDarker;
		color: $white;
	}
	span, 
	p {
		
		font-size: 1.11em;
		font-weight: 700;
		text-transform: uppercase;
	}
}

@mixin button-blue() {
	color: $white;
	background-color: $blue;
	border-bottom: 0.44em solid $bluegradient;
	@include box-shadow(0, 0.17em, 0.44em, 0, rgba($blueShadow, 0.4));
	&:hover,
	&:focus,
	&:active {
		background-color: $bluegradient;
		border-bottom-color: $blueDarker;
		color: $white;
	}
	span, 
	p {
		
		font-size: 1.11em;
		font-weight: 700;
		text-transform: uppercase;
	}
}

@mixin button-beige-light() {
	color: $text-dark;
	background-color: $beige;
	border-bottom: 0.44em solid $beigeDark;
	
	&:hover,
	&:focus,
	&:active {
		background-color: darken($beige, 3%);
		border-bottom-color: darken($beigeDark, 3%);			
	}
	span,
	p {
		font-size: 1.11em;
		font-weight: 700;
		text-transform: uppercase;
	}
}

@mixin button-blue-light() {
	color: $text-dark;
	background-color: $blueLight;
	border-bottom: 0.44em solid $blueGrey;
	
	&:hover,
	&:focus,
	&:active {
		background-color: darken($blueLight, 3%);
		border-bottom-color: darken($blueGrey, 3%);			
	}
	span,
	p {
		font-size: 1.11em;
		font-weight: 700;
		text-transform: uppercase;
	}
}

@mixin button-purple-dark() {
	color: $white;
	background-color: $purple;
	border-bottom: 0.44em solid $purpleDarker;
	@include box-shadow(0, 0.17em, 0.44em, 0, rgba($blueShadow, 0.4));
	&:hover,
	&:focus,
	&:active {
		color: $white;
		background-color: darken($purple, 3%);
		border-bottom-color: darken($purpleDarker, 3%);
	}
	span,
	p {
		font-size: 1.11em;
		font-weight: 700;
		text-transform: uppercase;
	}
}

@mixin button-blue-dark() {
	color: $white;
	background-color: $bluegradient;
	border-bottom: 0.44em solid $blueDarker;
	@include box-shadow(0, 0.17em, 0.44em, 0, rgba($blueShadow, 0.4));
	&:hover,
	&:focus,
	&:active {
		color: $white;
		background-color: darken($bluegradient, 3%);
		border-bottom-color: darken($blueDarker, 3%);
	}
	span,
	p {
		font-size: 1.11em;
		font-weight: 700;
		text-transform: uppercase;
	}
}

@mixin button-green() {
	color: $white;
	background-color: $green;
	border-bottom: 0.44em solid $greenDark;
	@include box-shadow(0, 0.17em, 0.44em, 0, rgba($blueShadow, 0.4));
	&:hover,
	&:focus,
	&:active {
		color: $white;
		background-color: darken($green, 3%);
		border-bottom-color: darken($greenDark, 3%);
	}
	span,
	p {
		color: $white;
		font-size: 1.11em;
		font-weight: 700;
	}
}

@mixin button-red() {
	color: $white;
	background-color: $red;
	border-bottom: 0.44em solid $redDark;
	@include box-shadow(0, 0.17em, 0.44em, 0, rgba($blueShadow, 0.4));
	&:hover,
	&:focus,
	&:active {
		color: $white;
		background-color: darken($red, 3%);
		border-bottom-color: darken($redDark, 3%);
	}
	span,
	p {
		font-size: 1.11em;
		font-weight: 700;
	}
}

@mixin button-yellow() {
	color: $white;
	background-color: $gold;
	border-bottom: 0.44em solid $goldDark;
	@include box-shadow(0, 0.17em, 0.44em, 0, rgba($blueShadow, 0.4));
	&:hover,
	&:focus,
	&:active {
		color: $white;
		background-color: darken($gold, 3%);
		border-bottom-color: darken($goldDark, 3%);
	}
	span,
	p {
		font-size: 1.11em;
		font-weight: 700;
		text-transform: uppercase;
	}
}