@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
@import '../../../styles/buttons';
.Button {
	position: relative;
	width: 100%;
	height: 2.25em;
	border-radius: 0.55em;
	font-weight: bold;
	text-align: center;
	text-decoration: none;
	cursor: pointer;
	@include no-select();
	@include opacity(1);
	@include flex('center', 'center');
	span {
		display: inline-block;
	}
	&.disabled,
	&.locked {
		opacity: 0.4;
		cursor: not-allowed;
		&:hover,
		&:focus,
		&:active {
			opacity: 0.4;
		}
	}
	&.disabled {
		pointer-events: none;
	}
	&.loading {
		&::after {
			content: "";
			position: absolute;
			width: 100%;
			height: 100%;
			pointer-events: none;
			background-image: url('../../../assets/images/icons/icon-loading.svg');
			background-size: auto 80%;
			background-position: right 1em center;
		}	
	}

	&.logout,
	&.settings {
		width: 8em;
		color: $text-dark;
		background-color: $offwhite;
		border-radius: 0.25em;
		@include box-shadow(0, 0.16em, 0.32em, 0, $black);
		span {
			font-size: 1.8em;
		}
	}

	&.audio {
		width: 3em;
		height: 3em;
		background-size: contain;

		&.playing {
			background-image: url(../../../assets/images/icons/icon-speaker.svg);
		}

		&.notPlaying {
			background-image: url(../../../assets/images/icons/icon-speaker-muted.svg);
		}
	}
	&.bold {
		span {
			font-weight: bold;
		}
	}

	&.login {
		width: 18.33em;
		height: 3em;
		border-radius: 1.28em;
		@include button-blue();

		&.game2 {
			@include button-purple();
		}

		&.submit {
			border: none;
			font-size: 1em;
		}
	}

	&.blueRounded {
		position: relative;
		width: 11.3em;
		height: 3em;
		padding: 0.7em;
		line-height: 1em;
		background-color: $blue;
		border-radius: 1.5em;
		border-bottom: 0.44em solid $bluegradient;
		@include box-shadow(0, 0.17em, 0.44em, 0, rgba($blueShadow, 0.4));

		&.game2 {
			background-color: $purpleLight;
			border-bottom: 0.44em solid $purpleDark;
		}

		&:hover {
			background-color: $bluegradient;
			border-bottom: 0.44em solid $blueDarker;

			&.game2 {
				background-color: $purple;
				border-bottom: 0.44em solid $purpleDarker;
			}
		}

		&.hint {
			&::before {
				position: absolute;
				left: 1em;
				bottom: -0.4em;
				content: '';
				height: 3.7em;
				width: 2.2em;
				background-image: url(../../../assets/images/icons/icon-lightbulb.svg);
				background-size: contain; 
			}
		}

		&.next {
			min-width: 14em;
			width: 100%;
			background-image: url(../../../assets/images/icons/icon-arrow-right.svg);
			background-size: contain;
			background-position: right 0.25em bottom 0;
			background-origin: content-box;
		}

		span {
			color: $white;
			font-size: 1.11em;
			font-weight: 700;
			text-transform: uppercase;
		}
	}

	&.select-group {
		height: 3em;
		background-color: $blueLight;
		color: #0B2047;
		border-radius: 1.25em;
		margin-bottom: 0.5em;
	}

	&.flatBlueLight {
		height: 2.6em;
		border-radius: 1.3em;
		background-color: $blueGrey;

		span {
			font-size: 1.1em;
			font-weight: bold;
		}
	}

	&.blueLight {
		height: 3em;
		border-radius: 1.25em;
		@include button-blue-light();

		&.padded {
			padding-right: 5.5em;
			padding-left: 1.5em;
		}

		&.game2 {
			@include button-beige-light();
		}
	}

	&.assignment {
		height: 3em;
		border-radius: 0.44em;
		@include button-blue();

		&.game2 {
			@include button-purple();
		}
		
		&.locked {
			background-image: url(../../../assets/images/icons/icon-lock.svg);
			background-size: 1.2em 1.7em;
			background-position: right 1em bottom 0.45em;
			&.disabled {
				opacity: 1;
			}
		}

		&.completed {
			@include button-yellow();
			background-image: url(../../../assets/images/icons/icon-check.svg);
			background-size: auto 1em;
			background-position: right 1em center;
		}

		&.current {
			background-color: $bluegradient;
			border-bottom-color: $blueDarker;
			
			&.game2 {
				background-color: $purple;
				border-bottom-color: $blueDark;
			}
		}
	}

	&.confirmTask {
		height: 2.67em;
		border-radius: 1.75em;
		@include button-blue();
		&.game2 {
			@include button-purple();
		}
		span {
			font-size: 1.22em;
			font-weight: bold;
			text-transform: uppercase;
		}
	}


	&.trophy {
		margin-top: 0.66em;
		&::after {
			content: "";
			position: absolute;
			width: 3.33em;
			height: 3.32em;
			pointer-events: none;
			background-image: url('../../../assets/images/icons/icon-trophy.svg');
			background-size: 3.33em 3.32em;
			background-position: top center;
			margin-bottom: 0.33em;
			left: 1.5em;
		}
	}

	
	&.countdown {
		margin-top: 0.66em;
		span {
			margin-left: 1.03em;
		}

		&::after {
			content: "";
			position: absolute;
			width: 3.36em;
			height: 3.19em;
			pointer-events: none;
			background-image: url('../../../assets/images/icons/icon-clock.svg');
			background-size: 3.36em 3.19em;
			background-position: top center;
			margin-bottom: 0.46em;
			left: 1.20em;
		}
	}

	&.backBtnGame {
		@include button-blue-light();

		&.game2 {
			@include button-beige-light();
		}
		
		border-radius: 1.28em;
		height: 3em;
		&::after {
			content: '';
			position: absolute;
			left: 1em;
			top: 0;
			width: 1em;
			height: 100%;
			background-image: url('../../../assets/images/icons/icon-arrow-left.svg');
			background-size: contain;
			background-position: center center;

		}
	}

	&.backBtn {
		&::after {
			content: "";
			position: absolute;
			width: 100%;
			height: 100%;
			pointer-events: none;
			background-image: url('../../../assets/images/icons/icon-back.svg');
			background-size: 1.88em 1.16em;
			background-position: left 0.75em center;
		}
	}

	&.video {
		&::after {
			content: "";
			position: absolute;
			width: 100%;
			height: 100%;
			pointer-events: none;
			background-image: url('../../../assets/images/icons/icon-arrow-right-white.svg');
			background-size: 1.88em 1.16em;
			background-position: right 0.75em center;
		}
	}
	&.logout {
		&::after {
			content: "";
			position: absolute;
			width: 100%;
			height: 100%;
			pointer-events: none;
			background-image: url('../../../assets/images/icons/icon-logout.svg');
			background-size: 1.88em 1.16em;
			background-position: right 0.95em center;
		}
	}
	&.download {
		&::after {
			content: "";
			position: absolute;
			width: 100%;
			height: 100%;
			pointer-events: none;
			background-image: url('../../../assets/images/icons/icon-download.svg');
			background-size: 1.88em 1.16em;
			background-position: right 0.75em center;
		}
	}

	&.responsive {
		@include transition(0.1s);
		
		&:hover {
			&:not(.noScaling) {
				@include scale(1.1);
			}
			background-color: $white;
			border-bottom-color: $blueHighlight;
		}
	}

	&.fullWidth {
		width: 100%;
	}
}