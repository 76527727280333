@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
@import '../../../styles/buttons';
.Info {
	position: relative;
	width: 24.7em;

	.Info-header {
		@include flex('','center','row');
		width: 100%;
		height: 5.1em;
		background-color: $blueWhite;
		border-top-left-radius: 0.55em;
		border-top-right-radius: 0.55em;

		&.game2 {
			background-color: $beige;
		}
		
		.Info-infoIcon {
			background-image: url('../../../assets/images/icons/icon-info.svg');
			background-size: contain;
			width: 3.4em;
			height: 4em;
			margin-left: 1.4em;

			&.game2 {
				background-image: url('../../../assets/images/icons/icon-info2.svg');
			}
		}
		.Info-titleWrapper {
			width: 17.5em;
			height: 3.667em;
			@include flex('center','flex-start','column');
			margin-left: 1.6em;
			.Info-title {
				span p {
					text-transform: uppercase;
					color: $text-dark;
					font-weight: 900;
					font-size: 1.67em;
					margin: 0;
				}
			}
			.Info-subtitle {
				span p {
					text-transform: uppercase;
					color: $text-dark;
					font-weight: 700;
					font-size: 1.333em;
					margin: 0;
				}
			}
		}
	}

	.Info-content {
		@include box-shadow(0, 0.16em, 0.4em, 0, rgba($blueShadow, 0.4));
		padding: 1.5em;
		border-bottom-left-radius: 0.55em;
		border-bottom-right-radius: 0.55em;
		background-color: $white;
		max-height: 38em;
		overflow: auto;
		@include custom-scrollbar(0.77em, $blueDarker, $blueGrey, 0.55em);
		.Info-text {
			p, li {
				color: $text-dark;
				font-size: 1.17em;
				margin-top: 0;
				&:last-of-type {
					margin-bottom: 0;
				}
			}
		}
		.Info-files {
			margin-top: 1em;
		}
		
	}
}
