@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
@import '../../../styles/buttons';
.Pairs {
	position: relative;
	width: 100%;
	height: 100%;
	padding: 1.67em 2.44em;
	overflow: auto;
	scroll-behavior: smooth;
	-webkit-overflow-scrolling: touch;
	@include hide-scrollbar();
		
	.Pairs-intro {
		position: relative;
		width: 100%;
	}

	.Pairs-content {
		@include flex('space-between', 'flex-top');
		margin: 2em 0 1.5em 0;
		.Pairs-columnA,
		.Pairs-columnB {
			width: 27em;
			height: 31.3em;
			border: 0.11em solid $blueGrey;
			border-radius: 0.56em;
			padding: 1.22em;
			.Pairs-item {
				width: 100%;
				min-height: 3em;
				line-height: 1.25em;
				border-radius: 0.55em;
				font-weight: bold;
				text-align: center;
				margin: 0 auto 0.8em auto;
				
				cursor: pointer;
				@include flex('center', 'center');
				@include no-select();
				@include button-blue();
				p {
					text-transform: none !important;
				}

				&.game2 {
					@include button-purple();
					p {
						text-transform: none !important;
					}
				}

				&.selected {
					@include button-blue-dark();
					&.game2 {
						@include button-purple-dark();
					}
				}
				&.completed {
					cursor: default;
				}
				&.completed,
				&.animateCorrect {
					@include button-green();
				}
				&.animateCorrect {
					cursor: not-allowed;
					@include animateCorrect();
				}
				&.animateWrong {
					cursor: not-allowed;
					@include button-red();
					@include animateWrong();
				}
			}
		}	
	}
	
}