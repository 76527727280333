@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
@import '../../../styles/buttons';
.PopupGameStatusWarning {
	position: absolute;
	height: 100%;
	width: 100%;
	@include flex('center', 'center', 'column');
	background: rgba($blueDark, 0.5);
	backdrop-filter: blur(1px);
	
	.PopupGameStatusWarning-content {
		position: relative;
		width: 24.72em;
		height: 23.22em;
		background-color: white;
		border-radius: 0.56em;
		padding: 3.2em 2.5em 2.5em 2.5em;
		@include box-shadow(0, 0.15em, 0.67em, 0, rgba($black, 0.2));
		margin-top: 5.39em;
		
		.PopupGameStatusWarning-title { 
			text-align: left;
			margin-bottom: 0.74em;
			span {
				font-size: 1.33em;
				font-weight: 500;
				color: $darkBlue;
			}
		}
		.PopupGameStatusWarning-text { 
			text-align: left;
			margin-bottom: 1.72em;
			p {
				margin: 0;
				font-size: 1.17em;
				color: $darkBlue;
			}
		}

		.PopupGameStatusWarning-buttons {
			margin: auto;
			width: 18em;
			height: 3em;
			.PopupGameStatusWarning-confirmBtn {
				width: 100%;
			}
			.PopupGameStatusWarning-cancelBtn {
				margin-top: 1.33em;
				width: 100%;
			}
		}
	}
}
