@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
@import '../../../styles/buttons';
.PopupCaseFile {
	position: absolute;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	@include flex('center', 'center', 'column');
	background: rgba($darkBlue, 0.5);
	z-index: 5;

	
	.PopupCaseFile-content {
		position: relative;
		background-color: white;
		border-radius: 0.56em;
		padding: 1.25em 1.5em;
		
		.PopupCaseFile-image {
			&.design-guide {
				width: 74.5em;
				height: 48.56em;
				background-image: url('../../../assets/images/case-files/design-guide.png');
			}
			&.color-guide {
				width: 56em;
				height: 48.56em;
				background-image: url('../../../assets/images/case-files/color-guide.jpg');
			}
			&.economy-numbers {
				width: 74.5em;
				height: 31.5em;
				background-image: url('../../../assets/images/case-files/economy-numbers.png');
			}
		}

		.PopupCaseFile-closeBtn {
			position: absolute;
			top: 0em;
			right: 0em;
			width: 3.88em;
			height: 3.55em;
			background-image: url('../../../assets/images/icons/icon-close.svg');
			background-size: 2.11em auto;
			background-position: center center;
			cursor: pointer;
			&:hover {
				background-size: 2.5em auto;
			}
		}
	}
}
