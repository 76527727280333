@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
@import '../../styles/buttons';
.Case {
	@include flex('','','column');
	position: relative;
	width: 100%;
	height: 100%;
	padding: 2em 0 0 0;
	
	.Case-header {
		.Case-logo {
			position: relative;
			padding-left: 2.8em;
			margin-right: 31.45em;
		}
		@include flex();
		margin-bottom: 1.60em;
		.Case-groupName {
			margin-left: 1em;
		}
	}

	.Case-contentWrapper {
		height: 100%;
		@include flex('','','row');
		.Case-sideWrapper {
			position: relative;
			height: 100%;
			@include flex('', '', 'column');
	
			.Case-infoWindow {
				position: relative;
				margin-left: 2.8em;
				margin-top: 1.9em;
			}
			.Case-areaButton {
				position: relative;
				margin-left: 4.18em;
				margin-top: 2.06em;
				height: 14.33em;
			}
			.Case-assignments {
				position: relative;
				margin-left: 2.85em;
				padding-top: 0.8em;
				width: 23.11em;
				max-height: 25em;
				overflow: auto;
				@include hide-scrollbar();
				background-color: $white;
				border-radius: 0.44em;
				@include flex('flex-start', 'center', 'column');
				margin-top: 1.72em;
				.Case-title {
					@include no-select();
					margin-top: 0.5em;
					margin-bottom: 0.5em;
					span {
						display: block;
						color: $darkBlue;
						font-size: 1.11em;
						font-weight: 700;
						text-transform: uppercase;
						text-align: center;
					}
				}
				.Case-infoBtn {
					position: absolute;
					top: 0.24em;
					right: 0.35em;
					width: 3.2em;
					height: 3.5em;
					background-image: url('../../assets/images/icons/icon-info.svg');
					background-size: auto 3.2em;
					background-position: center center;

					&.game2 {
						background-image: url('../../assets/images/icons/icon-info2.svg');
					}

					cursor: pointer;
					&:hover {
						background-size: auto 3.4em;
					}
				}
				
				.Case-casesButton {
					margin-top: 0.5em;
					margin-bottom: 0.75em;
					span {
						color: $white;
					}

					.Case-lockLine {
						position: absolute;
						z-index: 1;
						right: 1.2em;
						border: solid;
						border-left: none;
						border-color: $blueBorder;
						border-width: 0.33em;
						border-top-right-radius: 0.55em;
						border-bottom-right-radius: 0.55em;

						height: 4em;
						width: 4em;
						margin-top: -2.5em;
					}
					.Case-button {
						position: relative;
						width: 19.44em;
						z-index: 2;
					}
				}
			}
			.Case-backBtn {
				position: absolute;
				left: 2.8em;
				bottom: 2em;
				width: 14.44em;
			}
		}
	
		.Case-middleWrapper {
			@include flex('', '', 'column');
			margin-left: 7.4em;

			.Case-assignmentWrapper {
				width: 60em;
			}
		}
	}
}