@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
@import '../../styles/buttons';
.Admin {
	position: relative;
	height: 100%;
	padding: 7em 3.75em 0 3.75em;

	.Admin-logoutBtn {
		position: absolute;
		bottom: 1.83em;
		left: 3.75em;
	}

	.Admin-title {
		position: absolute;
		top: 2.7em;
		left: 4.8em;
		span {
			font-size: 2em;
			text-transform: uppercase;
			font-weight: 900;
			color: $black;
		}
	}

	.Admin-schools {
		width: 100em;
		border-radius: 0.56em;
		background-color: $white;
		padding: 1.6em;
		@include flex('', 'center', 'column');
		.Admin-createSchoolBtn {
			margin-bottom: 1.5em;
		}
		.Admin-rows {
			width: 100%;
			max-height: 35em;
			overflow: auto;
			padding: 0 1em;
			@include custom-scrollbar(0.77em, $blueDarker, $blueGrey, 0.55em);
		}
		.Admin-row {
			width: 100%;
			padding: 1em;
			@include flex('space-between', 'center', 'row');
			.Admin-item {
				width: 12em;
				text-align: center;
				&.name,
				&.email {
					width: 22em;
					text-align: start;
				}
				&.password {
					text-align: start;
					width: 15em;
				}
				&.edit {
					width: 5em;
				}
				&.delete {
					width: 2.1em;
					margin-left: 2.9em;
					text-align: end;
				}
			}
			&.header {
				background-color: $blue;
				.Admin-item {
					span {
						font-weight: 500;
						color: $white;
					}
				}
			}
			&.body {
				padding: 0.33em 0;
				.Admin-item {
					span {
						font-size: 1.17em;
						color: $darkBlue;
					}
					&.name span {
						font-weight: 500;
					}
					&.edit,
					&.delete {
						background-image: url('../../assets/images/icons/icon-edit.svg');
						background-position: center center;
						background-size: auto 1.8em;
						height: 2.1em;
						cursor: pointer;
						&:hover {
							background-size: auto 2em;
						}
					}
					&.delete {
						background-image: url('../../assets/images/icons/icon-close.svg');
					}
				}
			}
		}
	}
}