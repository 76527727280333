@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
@import '../../../styles/buttons';
.SortDndItem {
	position: relative;
	cursor: move;

	&:not(.image) {
		width: 23.89em;
		height: 3em;
		border-radius: 0.44em;
		margin-bottom: 0.75em;
		padding: 0 2.25em 0 0.75em;
		background-image: url('../../../assets/images/icons/icon-move.svg');
		background-size: 1.67em auto;
		background-position: right 0.7em center;
		@include button-blue();
		@include no-select();
		@include flex('flex-start', 'center');

		&.game2 {
			@include button-purple();
		
			p {
				text-transform: none;
			}
		}

		p {
			text-transform: none;
		}

		&.disabled {
			background-image: none;
		}

		&.completed,
		&.animateCorrect {
			@include button-green();
			span {
				text-transform: none ;
			}
		}
		&.animateWrong {
			@include button-red();
			span {
				text-transform: none ;
			}
		}

		&.double-height {
			height: 6em;
		}
	}

	&.image {
		&.completed,
		&.animateCorrect,
		&.animateWrong {
			&::after {
				content: '';
				position: absolute;
				bottom: -1em;
				right: -1em;
				width: 2.5em;
				height: 2.5em;
				background-image: url('../../../assets/images/icons/icon-correct.svg');
			}
		}
		&.animateWrong {
			&::after {
				background-image: url('../../../assets/images/icons/icon-wrong.svg');
			}
		}
	}

	&.disabled {
		cursor: not-allowed;
	}
	&.animateCorrect {
		@include animateCorrect();
	}
	&.animateWrong {
		@include animateWrong();
	}

	&.prep-extinguishers {
		width: 12.56em;
		height: 12.56em;
		background-color: $white;
		border-radius: 0.56em;
		@include box-shadow(0, 0, 0.44em, 0, rgba($black, 0.3));

		&.item-1 {
			background-image: url('../../../assets/images/tasks/sort/prep-extinguishers/item-1.svg');
			background-size: 42.7% auto;
		}
		&.item-2 {
			background-image: url('../../../assets/images/tasks/sort/prep-extinguishers/item-2.svg');
			background-size: 81.3% auto;
		}
		&.item-3 {
			background-image: url('../../../assets/images/tasks/sort/prep-extinguishers/item-3.svg');
			background-size: 43.8% auto;
		}
		&.item-4 {
			background-image: url('../../../assets/images/tasks/sort/prep-extinguishers/item-4.svg');
			background-size: 42.7% auto;
		}

		&.placed {
			width: 5.94em;
			height: 5.94em;
			margin: 0.5em;
		}
	}

	&.prep-shelves {
		margin: 0.5em;
		width: 8.6em;
		height: 8.6em;
		background-color: $white;
		border-radius: 0.56em;
		@include box-shadow(0, 0, 0.44em, 0, rgba($black, 0.3));

		&.item-1 {
			background-image: url('../../../assets/images/tasks/sort/prep-shelves/item-1.svg');
			background-size: 40% auto;
		}
		&.item-2 {
			background-image: url('../../../assets/images/tasks/sort/prep-shelves/item-2.svg');
			background-size: 45% auto;
		}
		&.item-3 {
			background-image: url('../../../assets/images/tasks/sort/prep-shelves/item-3.svg');
			background-size: 70% auto;
		}
		&.item-4 {
			background-image: url('../../../assets/images/tasks/sort/prep-shelves/item-4.svg');
			background-size: 70% auto;
		}
		&.item-5 {
			background-image: url('../../../assets/images/tasks/sort/prep-shelves/item-5.svg');
			background-size: 55% auto;
		}
		&.item-6 {
			background-image: url('../../../assets/images/tasks/sort/prep-shelves/item-6.svg');
			background-size: 90% auto;
		}

		&.placed {
			width: 5.94em;
			height: 5.94em;
			margin: 0.5em;
			
			background-color: transparent;
			border-radius: 0.56em;
			@include box-shadow(0, 0, 0, 0, rgba($black, 0));

			&.item-1 {
				background-size: auto 100%;
			}
			&.item-2 {
				background-size: auto 100%;
			}
			&.item-3 {
				background-size: 100% auto;
			}
			&.item-4 {
				background-size: 90% auto;
			}
			&.item-5 {
				background-size: auto 100%;
			}
			&.item-6 {
				background-size: auto 85%;
			}
		}
	}

	&.sales-shop-window {
		width: 8.67em;
		height: 8.67em;
		background-color: $white;
		border-radius: 0.56em;
		@include box-shadow(0, 0, 0.44em, 0, rgba($black, 0.3));
		&.completed,
		&.animateCorrect,
		&.animateWrong {
			&::after {
				bottom: 1em;
				right: 1em;
				width: 2em;
				height: 2em;
			}
		}

		&.placed {
			height: 100%;
			width: 33.33%;
			background-color: transparent;
			@include no-shadow();

			&.item-1, &.item-2, &.item-3 {
				background-position: center top calc(50% - 1em);
				&.completed,
				&.animateCorrect,
				&.animateWrong {
					&::after {
						bottom: 2.5em;
					}
				}
			}
			&.item-4, &.item-5, &.item-6 {
				background-position: top left calc(50% + 0.15em);
				&:first-of-type {width: 32%;}
				&:nth-of-type(2) {width: 35%;}
				&:nth-of-type(3) {width: 32%;}
			}
			&.item-7, &.item-8, &.item-9 { 
				background-position: top left calc(50% + 0.2em);
				&:first-of-type {width: 32.5%;}
				&:nth-of-type(2) {width: 35%;}
				&:nth-of-type(3) {width: 31.5%;}
			}
		}			
		&.item-1 {
			background-image: url('../../../assets/images/tasks/sort/sales-shop-window/item-1.svg');
			background-size: 3.8em auto;
		}
		&.item-2 {
			background-image: url('../../../assets/images/tasks/sort/sales-shop-window/item-2.svg');
			background-size: 3.8em auto;
		}
		&.item-3 {
			background-image: url('../../../assets/images/tasks/sort/sales-shop-window/item-3.svg');
			background-size: 3.8em auto;
		}
		&.item-4 {
			background-image: url('../../../assets/images/tasks/sort/sales-shop-window/item-4.svg');
			background-size: 6.9em auto;
		}
		&.item-5 {
			background-image: url('../../../assets/images/tasks/sort/sales-shop-window/item-5.svg');
			background-size: 6.3em auto;
		}
		&.item-6 {
			background-image: url('../../../assets/images/tasks/sort/sales-shop-window/item-6.svg');
			background-size: 6.3em auto;
		}
		&.item-7 {
			background-image: url('../../../assets/images/tasks/sort/sales-shop-window/item-7.svg');
			background-size: 4.1em auto;
			&.placed {
				&.animateCorrect,
				&.completed {
					background-size: 4.8em auto;
				}
			}
		}
		&.item-8 {
			background-image: url('../../../assets/images/tasks/sort/sales-shop-window/item-8.svg');
			background-size: 4.1em auto;
			&.placed {
				&.animateCorrect,
				&.completed {
					background-size: 4.8em auto;
				}
			}
		}
		&.item-9 {
			background-image: url('../../../assets/images/tasks/sort/sales-shop-window/item-9.svg');
			background-size: 4.1em auto;
			&.placed {
				&.animateCorrect,
				&.completed {
					background-size: 4.8em auto;
				}
			}
		}
	}
}