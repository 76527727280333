@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
@import '../../../styles/buttons';
.SpotErrors {
	position: relative;
	width: 100%;
	height: 100%;
	padding: 1.67em 2.44em;
	overflow: auto;
	scroll-behavior: smooth;
	-webkit-overflow-scrolling: touch;
	@include hide-scrollbar();

	.SpotErros-intro {
		position: relative;
		width: 100%;
	}

	.SpotErrors-errors {
		position: relative;
		width: 100%;
		height: 29em;
		margin: 2em 0 1.5em 0;
		background-size: 100% auto;
		background-position: top center;

		.SpotErrors-error {
			position: absolute;
			width: 5em;
			height: 5em;
			border-radius: 100%;
			// border: 2px solid #11466B;
			background-size: 2.5em auto;
			background-position: center center;
			&.spotted {
				background-image: url('../../../assets/images/icons/icon-correct.svg');
			}			
		}
	}

	.SpotErrors-confirmBtn {
		margin: auto;
		width: 19.44em;
	}
	


	&.completed {
		.SpotErrors-errors {
			.SpotErrors-error:not(.spotted) {
				background-image: url('../../../assets/images/icons/icon-wrong.svg');
			}
		}
	}

	&.management-meeting {
		.SpotErrors-errors {
			background-image: url('../../../assets/images/tasks/spot-errors/management-meeting.svg');	
			height: 32em;
			width: 16.37em;
			left: calc(50% - 0.5 * 16.37em);
			.SpotErrors-error {
				&.spotted {
					background-size: 1.5em auto;
					background-image: url('../../../assets/images/icons/icon-correct.svg');
				}

				&.messy-hair {
					top: 0em;
					left: calc(50% - 3em);
					width: 7.5em;
					height: 4.5em;
				}
				&.wrong-tie {
					top: 13.5em;
					left: calc(50% - 1.5em);
					width: 3em;
					height: 3em;
				}
				&.shirt-neck {
					top: 11.25em;
					left: calc(50% - 2.5em);
					width: 5em;
					height: 2em;
				}
				&.short-shirt {
					top: 26em;
					left: calc(50% - 8.25em);
					width: 3em;
					height: 2em;

					&::after {
						content: '';
						border-radius: 4em;
						position: absolute;
						top: 0em;
						left: 13.4em;
						width: 3em;
						height: 2em;
					}
				}
				&.buttons {
					top: 25em;
					left: calc(50% - 0.5em);
					width: 2em;
					height: 3em;
				}
				&.upside-down-smile {
					top: 9em;
					left: calc(50% - 2em);
					width: 4em;
					height: 1.5em;
				}
			}
		}
	}

	&.prep-safety-issues {
		.SpotErrors-errors {
			background-image: url('../../../assets/images/tasks/spot-errors/prep-safety-issues.svg');	
			.SpotErrors-error {
				&.firealarm-no-battery {
					top: 0.75em;
					left: calc(50% + 13.8em);
					width: 2.5em;
					height: 2.5em;
				}
				&.no-fire-extinguisher {
					top: 8.5em;
					left: calc(50% - 25.25em);
					width: 3em;
					height: 5em;
				}
				&.hidden-fire-hose {
					top: 6em;
					left: calc(50% + 19.75em);
					width: 6.25em;
					height: 6em;
				}
				&.blocking-box {
					top: 16.25em;
					left: calc(50% - 17em);
					width: 6.5em;
					height: 4.75em;
				}
				&.escape-lamp-not-functional {
					top: 2.75em;
					left: calc(50% - 17.5em);
					width: 3.75em;
					height: 3em;
				}
			}
		}
	}
	&.research-test-tubes {
		.SpotErrors-errors {
			background-image: url('../../../assets/images/tasks/spot-errors/research-test-tubes.svg');	
			.SpotErrors-error {
				&.test-tube1 {
					top: 11.25em;
					left: calc(50% + 18.25em);
					width: 3.25em;
					height: 2.75em;
				}
				&.test-tube2 {
					top: 16.75em;
					left: calc(50% + 18.3em);
					width: 3.25em;
					height: 2em;
				}
				&.test-tube3 {
					top: 15.75em;
					left: calc(50% + 8.4em);
					width: 4.25em;
					height: 2.5em;
				}
				&.test-tube4 {
					top: 17.25em;
					left: calc(50% + 0.25em);
					width: 3.75em;
					height: 3.25em;
				}
				&.test-tube5 {
					top: 10.75em;
					left: calc(50% - 20.75em);
					width: 3.75em;
					height: 2.5em;
				}
				&.test-tube6 {
					top: 16em;
					left: calc(50% - 25.75em);
					width: 4.5em;
					height: 3em;
				}
			}
		}
	}
	&.research-clean-up {
		.SpotErrors-errors {
			background-image: url('../../../assets/images/tasks/spot-errors/research-clean-up.svg');	
			.SpotErrors-error {
				&.mess1 {
					top: 11.25em;
					left: calc(50% + 18.25em);
					width: 3.25em;
					height: 2.75em;
				}
				&.mess2 {
					top: 17em;
					left: calc(50% + 9em);
					width: 6.75em;
					height: 6em;
				}
				&.mess3 {
					top: 11.25em;
					left: calc(50% + 2.4em);
					width: 7em;
					height: 2.5em;
				}
				&.mess4 {
					top: 11em;
					left: calc(50% - 11.4em);
					width: 4em;
					height: 3em;
				}
				&.mess5 {
					top: 14.5em;
					left: calc(50% - 21em);
					width: 9em;
					height: 2.5em;
				}
				&.mess6 {
					top: 18.75em;
					left: calc(50% - 26em);
					width: 7.5em;
					height: 5.5em;
				}
			}
		}
	}

	&.production-laboratory,
	&.sales-store {
		.SpotErrors-errors {
			background-image: url('../../../assets/images/tasks/spot-errors/production-laboratory.svg');	
			.SpotErrors-error {
				&.paper-on-floor {
					top: 20.5em;
					left: calc(50% + 17em);
					width: 10em;
					height: 4em;
				}
				&.broken-lamp {
					top: 0;
					left: calc(50% - 3.1em);
					height: 3.5em;
					width: 7em;
					border-top-left-radius: 0;
					border-top-right-radius: 0;
				}
				&.box-with-knife {
					top: 16.75em;
					left: calc(50% - 17.25em);
					width: 13em;
					height: 9.5em;
				}
				&.clothes {
					left: calc(50% - 25em);
					top: 11em;
					width: 7.25em;
					height: 6.25em;
				}
				&.sales-man {
					left: calc(50% + 7.25em);
					top: 6.75em;
					height: 21em;
					width: 8.5em;
				}
			}
		}
	}
	&.sales-store {
		.SpotErrors-errors {
			background-image: url('../../../assets/images/tasks/spot-errors/sales-store.svg');	
			.SpotErrors-error {
				&.paper-on-floor {
					top: 22em;
				}
				&.broken-lamp {
					left: calc(50% - 3.75em);
				}
				&.box-with-knife {
					left: calc(50% - 16.1em);
				}
				&.clothes {
					left: calc(50% - 25.5em);
					top: 17.5em;
				}
				&.sales-man {
					left: calc(50% + 6.25em);
				}
			}
		}
	}

	&.sales-receiving-products {
		.SpotErrors-errors {
			background-image: url('../../../assets/images/tasks/spot-errors/sales-receiving-products.svg');	
			.SpotErrors-error {
				&.boxes-on-pallet {
					top: 11em;
					left: calc(50% - 7.5em);
					width: 15em;
					height: 10em;
					border-radius: 0.5em;
				}
				&.driving-one-handed {
					top: 12.5em;
					left: calc(50% - 16em);
					width: 4em;
					height: 4em;
				}
				&.messy-boxes {
					top: 14.5em;
					left: calc(50% + 15.5em);
					width: 11em;
					height: 8em;
				}
				&.heavy-lifting {
					top: 11em;
					left: calc(50% + 7.6em);
					width: 8em;
					height: 10em;
				}
				&.liquid-on-floor {
					top: 24.7em;
					left: calc(50% - 27em);
					width: 10em;
					height: 3.5em;
				}
			}
		}
	}

	&.management-office {
		.SpotErrors-errors {
			background-image: url('../../../assets/images/tasks/spot-errors/management-office.svg');	
			.SpotErrors-error {
				&.messy-papers {
					top: 11em;
					left: calc(50% + 13.25em);
					width: 6em;
					height: 4em;
				}
				&.sitting-on-table {
					top: 11em;
					left: calc(50% - 3.5em);
					width: 5.5em;
					height: 5em;
				}
				&.open-drawers {
					top: 13em;
					left: calc(50% + 21.75em);
					width: 5.5em;
					height: 8em;
				}
				&.trash {
					top: 18.5em;
					left: calc(50% - 10em);
					width: 5em;
					height: 4em;
				}
				&.trophy {
					top: 17.5em;
					left: calc(50% - 21.5em);
					width: 4.5em;
					height: 5em;
				}
				&.dog {
					top: 9.5em;
					left: calc(50% + 5.25em);
					width: 6em;
					height: 7em;
				}
				// &.books {
				// 	top: 11em;
				// 	left: calc(50% - 7.5em);
				// 	width: 5em;
				// 	height: 5em;
				// }
			}
		}
	}

	&.it-does-not-belong {
		.SpotErrors-errors {
			background-image: url('../../../assets/images/tasks/spot-errors/it-does-not-belong.svg');	
			.SpotErrors-error {
				&.wire {
					top: 18em;
					left: calc(50% - 24.5em);
					width: 5em;
					height: 4em;
				}
				&.safety-glasses {
					top: 11.2em;
					left: calc(50% - 2em);
					width: 5em;
					height: 4em;
				}
				&.drone {
					top: 5em;
					left: calc(50% - 27.45em);
					width: 13em;
					height: 7.25em;
				}
				&.lamp {
					top: 0em;
					left: calc(50% - 22em);
					width: 7em;
					height: 2.5em;
					border-top-left-radius: 0;
					border-top-right-radius: 0;
				}
				&.ash-tray {
					top: 11.9em;
					left: calc(50% - 17.2em);
					width: 4.5em;
					height: 3em;
				}
			}
		}
	}
}