@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
@import '../../styles/buttons';
.Assignment {
	position: relative;
	.Assignment-header {
		@include no-select();
		@include flex('space-between','center','row');
		height: 5em;
		width: 100%;
		background-color: $blueLight;
		border-top-left-radius: 0.55em;
		border-top-right-radius: 0.55em;
		padding-left: 2.55em;
		padding-right: 1.55em;

		&.game2 {
			background-color: $beige;
		}
	
		.Assignment-title {
			color: $text-dark;
			span {
				font-size: 1.7em;
				font-weight: 900;
			}
		}
		.Assignment-maxPoints {
			@include flex('center','center');
			background-color: $white;
			width: 11.1em;
			height: 3em;
			border-radius: 1.5em;
	
			span {
				color: $text-dark;
				font-size: 1.33em;
				font-weight: bold;
			}
		}
	}
	.Assignment-hint {
		width: 11.3em;
	}

	.Assignment-content {
		position: relative;
		height: 45em;
		width: 100%;
		background-color: $white;
		@include box-shadow(0, 0.17em, 0.44em, 0, rgba($blueShadow, 0.4));
		border-bottom-left-radius: 0.55em;
		border-bottom-right-radius: 0.55em;

		.Assignment-navigation {
			position: absolute;
			bottom: 1.4em;
			left: calc(50% - 7em);
			margin-top: 0.1em;
		
			.Assignment-backToOffice {
				width: 17em;
			}
		}
	}
}