@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
@import '../../../styles/buttons';
.PopupAssignmentHint {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: rgba($darkBlue, 0.5);
	z-index: 10;

	.PopupAssignmentHint-content {
		position: absolute;
		top: 19.5em;
		left: 47em;
		width: 33.3em;
		
		.PopupAssignmentHint-header {
			@include flex('flex-start', 'center');
			height: 5.1em;
			background-color: $blueWhite;
			border-top-left-radius: 0.55em;
			border-top-right-radius: 0.55em;
			padding-left: 6em;
			background-image: url('../../../assets/images/icons/icon-lightbulb.svg');
			background-size: auto 3.89em;
			background-position: left 2em center;
			span {
				font-size: 1.67em;
				color: $text-dark;
				font-weight: 900;
			}
		}

		.PopupAssignmentHint-body {
			@include flex('space-between', 'center', 'column');
			min-height: 11.67em;
			background-color: $white;
			padding: 1.67em;
			border-bottom-left-radius: 0.55em;
			border-bottom-right-radius: 0.55em;
			
			.PopupAssignmentHint-image {
				background-size: contain;
				&.production-process {
					width: 30em;
					height: 20em;
					background-image: url('../../../assets/images/tasks/hint/production-process.png');
				}
			}

			.PopupAssignmentHint-text {
				padding-bottom: 1em;
				span {
					font-size: 1.16em;
					color: $text-dark;
				}
			}

			.PopupAssignmentHint-button {
				color: $text-light;
			}
		}
	}

}