@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
@import '../../../styles/buttons';
.InputAnswer {
	position: relative;
	width: 100%;
	height: 100%;
	padding: 1.67em 2.44em;
	overflow: auto;
	scroll-behavior: smooth;
	-webkit-overflow-scrolling: touch;
	@include hide-scrollbar();
		
	.InputAnswer-intro {
		margin-bottom: 1.67em;
		p {
			color: $darkBlue;
			font-size: 1.17em;
			line-height: 1.61;
			margin-bottom: 0;
			&:first-of-type {margin-top: 0;}
		}
	}

	.InputAnswer-form {
		@include flex('center', 'center', 'column');
		
		.InputAnswer-input {
			@include flex('center', 'center');
			margin-bottom: 1.83em;
			color: $darkBlue;
			input {
				display: block;
				width: 5.61em;
				height: 2.78em;
				margin-right: 0.83em;
				border-radius: 0.44em;
				background-color: $blueLight;
				padding: 0 0.5em;
				border: solid 0.11em $blueGrey;
				margin-left: 0.75em;
				&::placeholder {color: $darkBlue;}
			}
			p {
				margin: 0;
				font-weight: bold;
				font-size: 1.33em;
			}
			&.disabled {
				input {
					background-color: transparent;
				}
			}
			&.correct input {
				border-color: $green;
			}
			&.wrong input {
				border-color: $red;
			}
		}

		.InputAnswer-answerButton {
			width: 19.44em;
		}

		.InputAnswer-correctAnswer {
			p {
				margin: 0;
				color: $darkBlue;
				font-weight: bold;
				font-size: 1.33em;	
			}
		}
	}
}