@import '../styles/variables';
@import '../styles/fonts';
@import '../styles/mixins';
@import '../styles/animations';
@import '../styles/buttons';
.App {
	position: relative;
	height: 100%;
	width: 100%;

	.App-audioWrapper {
		position: absolute;
		top: 2em;
		right: 2em;
		z-index: 2;
	}
}