@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
@import '../../../styles/buttons';
.AreaButton {
	width: 19.4em;
	@include flex('', 'center', 'column');
	@include transition(0.1s);
	pointer-events: none;
	
	&:hover {
		@include scale(1.1);
	}

	&:hover .AreaButton-barWrapper {
		.AreaButton-bar {
			background-color: $white;
		}
		.AreaButton-bottomBar {
			background-color: $blueHighlight;
		}
	}

	&:hover .AreaButton-circle {
		background: radial-gradient($white, $blueHighlight);
	}

	.AreaButton-complete {
		position: absolute;
		background-image: url(../../../assets/images/icons/icon-done.svg);
		width: 2.67em;
		height: 3em;
		bottom: 2.55em;
		right: 4.17em;
	}

	.AreaButton-circle {
		@include flex('center', 'center');
		cursor: pointer;
		width: 10.67em;
		height: 10.67em;
		border-radius: 10em;
		background: radial-gradient($blueWhite, $blueGrey);
		pointer-events: auto;
		@include box-shadow(0, 0.16em, 0.4em, 0, rgba($blueShadow, 0.4));

		&.game2 {
			background: radial-gradient($beige, $beigeLight);
		}

		.AreaButton-icon {
			background-size: contain;

			&.it {
				background-image: url(../../../assets/images/area-bubbles/it.svg);
				width: 7.1em;
				height: 6.3em;
			}
			&.research {
				background-image: url(../../../assets/images/area-bubbles/research.svg);
				width: 4.9em;
				height: 7.45em;
			}
			&.build {
				background-image: url(../../../assets/images/area-bubbles/build.svg);
				width: 4.27em;
				height: 7.83em;
			}
			&.prep {
				background-image: url(../../../assets/images/area-bubbles/prep.svg);
				width: 4em;
				height: 8em;
			}
			&.design {
				background-image: url(../../../assets/images/area-bubbles/design.svg);
				width: 6.6em;
				height: 5.15em;
			}
			&.production {
				background-image: url(../../../assets/images/area-bubbles/production.svg);
				width: 7.8em;
				height: 6em;
			}
			&.management {
				background-image: url(../../../assets/images/area-bubbles/management.svg);
				width: 8.8em;
				height: 4.9em;
				margin-bottom: 0.75em;
			}
			&.marketing {
				background-image: url(../../../assets/images/area-bubbles/marketing.svg);
				width: 8.42em;
				height: 7.27em;
			}
			&.sales {
				background-image: url(../../../assets/images/area-bubbles/sales.svg);
				width: 7.12em;
				height: 7.08em;
			}
			&.economy {
				background-image: url(../../../assets/images/area-bubbles/economy.svg);
				width: 6.88em;
				height: 7.96em;
				margin-right: 0.75em;
			}
		}
	}

	.AreaButton-barWrapper {
		position: relative;
		margin-top: 2em;
		@include flex('center', 'center');
		.AreaButton-bottomBar {
			position: absolute;
			margin-top: 0.8em;
			width: 21em;
			height: 2.5em;
			border-radius: 1.2em;
			background-color: $blueGrey;
			pointer-events: auto;
			
			&.game2 {
				background-color: $beigeDark;
			}

			@include box-shadow(0, 0.16em, 0.4em, 0, rgba($blueShadow, 0.4));
		}
		.AreaButton-bar {
			@include no-select();
			@include flex('center', 'center');
			position: absolute;
			cursor: pointer;
			width: 21em;
			height: 2.5em;
			background-color: $blueWhite;
			border-radius: 1.2em;
			pointer-events: auto;
			
			&.game2 {
				background-color: $beige;
			}

			span {
				text-transform: uppercase;
				color: $text-dark;
				font-weight: bold;
				font-size: 1.1em;
			}
		}
	}
}