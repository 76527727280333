@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
@import '../../../styles/buttons';
.PaperDoll {
	position: relative;
	width: 100%;
	height: 100%;
	padding: 1.67em 2.44em;
	overflow: auto;
	scroll-behavior: smooth;
	-webkit-overflow-scrolling: touch;
	@include hide-scrollbar();

	.PaperDoll-intro {
		position: relative;
		width: 100%;
	}

	.PaperDoll-content {
		@include flex('space-between', 'flex-start');
		height: 30em;
		margin: 2em 0;
		.PaperDoll-availableItems {
			position: relative;
			width: 30em;
			height: 100%;
			background-color: $blueLight;
			border: 1px solid $blueGrey;
			border-radius: 0.56em;
			padding: 1em;
			@include flex('space-between', 'center');
			flex-flow: row wrap;
			.PaperDoll-item {
				width: 8.7em;
				height: 8.7em;
				border-radius: 0.56em;
				background-color: $white;
				@include box-shadow(0, 0, 0.44em, 0, rgba($black, 0.3));
				cursor: pointer;
				&.selected {
					cursor: default;
					background-color: $blueGrey;
					@include no-shadow();
					@include opacity(0.5);
				}
				&.correct {
					border: 1px solid $green;
				}
				&.wrong {
					border: 1px solid $red;
				}
			}
		}
		.PaperDoll-selectedItems {
			position: relative;
			width: 24em;
			height: 100%;
			border: 0.17em solid $blueGrey;
			border-radius: 0.56em;
			
			.PaperDoll-avatar {
				position: absolute;
				top: 0;
				bottom: 0;
				width: 100%;
				height: 100%;
				z-index: 2;
				background-image: url('../../../assets/images/tasks/paper-doll/avatar-1.svg');
				background-size: 13.57em auto;
				background-position: bottom center;
			}
			&.avatar-2 .PaperDoll-avatar {
				background-image: url('../../../assets/images/tasks/paper-doll/avatar-2.svg');
			}
			&.avatar-3 .PaperDoll-avatar {
				background-image: url('../../../assets/images/tasks/paper-doll/avatar-3.svg');
				background-size: 10.4em auto;
			}
		}
		.PaperDoll-availableItems,
		.PaperDoll-selectedItems {
			.PaperDoll-item {
				cursor: pointer;
				&.completed {
					cursor: default;
				}
				&.apron {
					background-image: url('../../../assets/images/tasks/paper-doll/apron.svg');
					background-size: 3.5em auto;
				}
				&.brains {
					background-image: url('../../../assets/images/tasks/paper-doll/brains.svg');
					background-size: 6em auto;
				}
				&.camera {
					background-image: url('../../../assets/images/tasks/paper-doll/camera.svg');
					background-size: 5em auto;
				}
				&.cigaret {
					background-image: url('../../../assets/images/tasks/paper-doll/cigaret.svg');
					background-size: 3.74em auto;
				}
				&.computer {
					background-image: url('../../../assets/images/tasks/paper-doll/computer.svg');
					background-size: 8em auto;
				}
				&.donut {
					background-image: url('../../../assets/images/tasks/paper-doll/donut.svg');
					background-size: 4.6em auto;
				}
				&.roll-and-ride-polo {
					background-image: url('../../../assets/images/tasks/paper-doll/roll-and-ride-polo.svg');
					background-size: 7.04em auto;
				}
				&.green-transition {
					background-image: url('../../../assets/images/tasks/paper-doll/green-transition.svg');
					background-size: 7.5em auto;
				}
				&.keys {
					background-image: url('../../../assets/images/tasks/paper-doll/keys.svg');
					background-size: 4.6em auto;
				}
				&.lab-coat {
					background-image: url('../../../assets/images/tasks/paper-doll/lab-coat.svg');
					background-size: 5.9em auto;
				}
				&.laptop {
					background-image: url('../../../assets/images/tasks/paper-doll/laptop.svg');
					background-size: 7.8em auto;
				}
				&.microscope {
					background-image: url('../../../assets/images/tasks/paper-doll/microscope.svg');
					background-size: 4.5em auto;
				}
				&.name-tag,
				&.name-tag2 {
					background-image: url('../../../assets/images/tasks/paper-doll/name-tag.svg');
					background-size: 6em auto;
				}
				&.note-book {
					background-image: url('../../../assets/images/tasks/paper-doll/note-book.svg');
					background-size: 5.6em auto;
				}
				&.office-chair {
					background-image: url('../../../assets/images/tasks/paper-doll/office-chair.svg');
					background-size: 3.61em auto;
				}
				&.pda {
					background-image: url('../../../assets/images/tasks/paper-doll/pda.svg');
					background-size: 3.44em auto;
				}
				&.pen,
				&.pen2 {
					background-image: url('../../../assets/images/tasks/paper-doll/pen.svg');
					background-size: 0.8em auto;
				}
				&.phone {
					background-image: url('../../../assets/images/tasks/paper-doll/phone.svg');
					background-size: 3em auto;
				}
				&.plastic-gloves {
					background-image: url('../../../assets/images/tasks/paper-doll/plastic-gloves.svg');
					background-size: 7em auto;
				}
				&.printer {
					background-image: url('../../../assets/images/tasks/paper-doll/printer.svg');
					background-size: 8em auto;
				}
				&.safety-glasses {
					background-image: url('../../../assets/images/tasks/paper-doll/safety-glasses.svg');
					background-size: 6.3em auto;
				}
				&.scissors {
					background-image: url('../../../assets/images/tasks/paper-doll/scissors.svg');
					background-size: 7em auto;
				}
				&.shirt {
					background-image: url('../../../assets/images/tasks/paper-doll/shirt.svg');
					background-size: 7em auto;
				}
				&.shirt-purple {
					background-image: url('../../../assets/images/tasks/paper-doll/shirt-purple.svg');
					background-size: 6.31em auto;
				}
				&.shirt-stained {
					background-image: url('../../../assets/images/tasks/paper-doll/shirt-stained.svg');
					background-size: 7em auto;
				}
				&.shorts {
					background-image: url('../../../assets/images/tasks/paper-doll/shorts.svg');
					background-size: 6.08em auto;
				}
				&.smile {
					background-image: url('../../../assets/images/tasks/paper-doll/smile.svg');
					background-size: 3.7em auto;
				}
				&.spray-bottle {
					background-image: url('../../../assets/images/tasks/paper-doll/spray-bottle.svg');
					background-size: 3.1em auto;
				}
				&.sunglasses {
					background-image: url('../../../assets/images/tasks/paper-doll/sunglasses.svg');
					background-size: 6.26em auto;
				}
				&.tie {
					background-image: url('../../../assets/images/tasks/paper-doll/tie.svg');
					background-size: 1.2em auto;
				}
				&.tv {
					background-image: url('../../../assets/images/tasks/paper-doll/tv.svg');
					background-size: 8em auto;
				}
				&.whistle {
					background-image: url('../../../assets/images/tasks/paper-doll/whistle.svg');
					background-size: 4em auto;
				}
			}
		}
		.PaperDoll-selectedItems {
			overflow: hidden;
			.PaperDoll-item {
				position: absolute;
				z-index: 3;
				background-size: contain !important;
				&.apron {
					width: 7em;
					height: 14.33em;
					left: calc(50% - 0.5 * 7em);
					bottom: 1.7em;
				}
				&.brains {
					width: 6em;
					height: 3.82em;
					left: calc(50% - 0.5 * 6em);
					bottom: 21.8em;
					@include opacity(0.7);
				}
				&.camera {
					background-image: url('../../../assets/images/tasks/paper-doll/camera2.svg');
					left: calc(50% - 0.5 * 4.44em);
					width: 4.44em;
					height: 8.87em;
					right: 1.5em;
					bottom: 7.3em;
				}
				&.cigaret {
					width: 3.14em;
					height: 0.47em;
					bottom: 18.2em;
					left: calc(50% - 3.5em);
					@include rotate(-15deg);
				}
				&.computer {
					width: 11.6em;
					height: 5.83em;
					right: 1.5em;
					bottom: 0em;
				}
				&.donut {
					width: 4.6em;
					height: 3.2em;
					bottom: 0;
					left: calc(50% + 0.1em);
				}
				&.roll-and-ride-polo {
					width: 12.8em;
					height: 13.6em;
					bottom: 2.65em;
					left: calc(50% - 0.5 * 12.8em);
				}
				&.green-transition {
					width: 7.5em;
					height: 5.86em;
					left: 0.7em;
					top: 0.8em;
				}
				&.keys {
					width: 3em;
					height: 1.5em;
					left: calc(50% + 5em);
					bottom: 0;
				}
				&.lab-coat {
					width: 12.92em;
					height: 16.49em;
					left: calc(50% - 0.5 * 12.92em);
					bottom: 0;
				}
				&.laptop {
					width: 11.6em;
					height: 5.84em;
					left: calc(50% + 0.1em);
					bottom: 0;
				}
				&.microscope {
					width: 4.5em;
					height: 7.33em;
					left: calc(50% - 10.5em);
					bottom: 0;
				}
				&.name-tag {
					width: 4em;
					height: 1.5em;
					bottom: 11.1em;
					left: calc(50% + 0.25em);
				}
				&.name-tag2 {
					width: 4em;
					height: 1.5em;
					bottom: 12.1em;
					left: calc(50% + 0.25em);
				}
				&.note-book {
					width: 5.6em;
					height: 6.6em;
					bottom: 0;
					left: calc(50% - 11.5em);
				}
				&.office-chair {
					width: 11.25em;
					height: 12.94em;
					background-image: url('../../../assets/images/tasks/paper-doll/office-chair-b.svg');
					bottom: 0;
					left: calc(50% - 12em);
					z-index: 1;
				}
				&.pda {
					width: 3.44em;
					height: 4.94em;
					bottom: 0;
					left: calc(50% - 11em);
				}
				&.pen {
					width: 0.5em;
					height: 4.4em;
					bottom: 9em;
					left: calc(50% - 3em);
				}
				&.pen2 {
					@include rotate(-90deg);
					width: 0.5em;
					height: 4.4em;
					bottom: -2em;
					right: 2.5em;
				}
				&.phone {
					width: 2.7em;
					height: 4.9em;
					bottom: 0em;
					left: 1.5em;
				}
				&.plastic-gloves {
					background-image: url('../../../assets/images/tasks/paper-doll/plastic-gloves-right.svg');
					width: 4em;
					height: 5.25em;
					bottom: -0.35em;
					left: calc(50% - 7.7em);

					&::after {
						content: '';
						background-image: url('../../../assets/images/tasks/paper-doll/plastic-gloves-left.svg');
						z-index: 4;
						position: absolute;
						width: 4em;
						height: 5.25em;
						bottom: 0em;
						left: calc(50% + 9.45em);
					}
				}
				&.printer {
					background-image: url('../../../assets/images/tasks/paper-doll/printer.svg');
					width: 10.82em;
					height: 5.83em;
					bottom: -0.3em;
					left: calc(50% - 0.5 * 10.82em);
				}
				&.safety-glasses {
					width: 6.3em;
					height: 2em;
					left: calc(50% - 0.5 * 6.3em);
					bottom: 20em;
				}
				&.scissors {
					width: 5.6em;
					height: 2.32em;
					left: calc(50% - 6em);
					bottom: 0;
				}
				&.shirt {
					width: 13.3em;
					height: 13.3em;
					left: calc(50% - 0.5 * 13.3em);
					bottom: 2.9em;
				}
				&.shirt-purple {
					width: 10.58em;
					height: 11.14em;
					left: calc(50% - 0.5 * 10.58em);
					bottom: 4.9em;
				}
				&.shirt-stained {
					width: 13.26em;
					height: 13.5em;
					left: calc(50% - 0.5 * 13.26em);
					bottom: 2.9em;
				}
				&.shorts {
					width: 8em;
					height: 7.67em;
					left: calc(50% - 0.5 * 8em);
					bottom: -4.75em;
				}
				&.smile {
					width: 3.7em;
					height: 1.5em;
					left: calc(50% - 0.5 * 3.7em);
					bottom: 17.6em;
				}
				&.spray-bottle {
					width: 3.1em;
					height: 7.11em;
					right: calc(50% - 11em);
					bottom: 0;
				}
				&.sunglasses {
					width: 6.16em;
					height: 2.01em;
					left: calc(50% - 0.5 * 6.16em + 0.1em);
					bottom: 19.55em;
				}
				&.tie {
					width: 1.2em;
					height: 7.6em;
					left: calc(50% - 0.5 * 1.2em);
					bottom: 7.7em;
				}
				&.tv {
					width: 16em;
					height: 12.55em;
					left: calc(50% - 0.5 * 16em);
					bottom: 0em;
				}
				&.whistle {
					width: 2.5em;
					height: 1.6em;
					left: 4em;
					bottom: -0.1em;
				}

			}
		}
	}

	.PaperDoll-confirmBtn {
		margin: auto;
		width: 19.44em;
	}


	&.economy-shopper-items {
		.PaperDoll-content {
			.PaperDoll-selectedItems {
				.PaperDoll-item {
					&.phone {
						width: 2.72em;
						height: 4.94em;
						bottom: 0;
						left: calc(50% - 4em);		

					}
				}
			}
		}
	}

	&.management-sales-manager {
		.PaperDoll-content {
			.PaperDoll-selectedItems {
				.PaperDoll-item {
					&.keys {
						width: 4.62em;
						height: 2.42em;
						left: calc(50% + 7em);

					}
				}
			}
		}
	}
}