@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
@import '../../../styles/buttons';
.Audio {
	.rhap_container {
		.rhap_main {
			.rhap_progress-section {
				display: none;
			}
			.rhap_controls-section {
				.rhap_additional-controls,
				.rhap_volume-controls {
					display: none;
				}
				.rhap_main-controls {
					display: none;
				}
			}
		}
	}
}