@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
@import '../../styles/buttons';
.GameOpen {
	height: 100%;
	width: 100%;
	@include flex('flex-start', 'center', 'column');
	.GameOpen-logo {
		position: absolute;
		top: 2em;
		left: 3.3em;
		cursor: pointer;
	}

	.GameOpen-schoolName {
		position: relative;
		margin-top: 1.5em;
		span {
			color: white;
			font-size: 2.5em;
			font-weight: 900;
			text-transform: uppercase;
		}
	}

	.GameOpen-leaderBoard {
		position: absolute;
		top: 9.25em;
		left: calc(50% - 30em);
		&.hidden {
			@include opacity(0);
			pointer-events: none;
		}
	}

	.GameOpen-buttons {
		position: absolute;
		bottom: 2em;
		left: 0em;
		width: 100%;
		padding: 0 2em;
		@include flex('space-between', 'flex-end');
		height: 3.66em;
		
		.GameOpen-backBtn {
			width: 14.44em;
		}
		.GameOpen-highscoreBtn,
		.GameOpen-closeBtn {
			width: 19.4em;
		}
	
	}
}
