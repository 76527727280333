@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
@import '../../../styles/buttons';
.OrderDndPreview {	
	.OrderDndPreview-item {
		position: relative;
		width: 23.89em;
		height: 3em;
		border-radius: 0.44em;
		padding: 0 2.25em 0 0.75em;
		cursor: move;
		background-image: url('../../../assets/images/icons/icon-move.svg');
		background-size: 1.67em auto;
		background-position: right 0.7em center;
		transform-origin: right 0.5em center;
		@include button-blue();
		@include no-select();
		@include flex('flex-start', 'center');
		@include rotate(-7deg);
		
		&.game2 {
			@include button-purple();
			span {
				text-transform: none;
			}
		}
		span {
			text-transform: none ;
		}
	}

	&.double-height {
		.OrderDndPreview-item {
			height: 6em;
		}
	}
}