@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
@import '../../../styles/buttons';
.SortDndDragLayer {
	.SortDndDragLayer-item {
		position: relative;
		cursor: move;
		&:not(.image) {
			width: 23.89em;
			height: 3em;
			border-radius: 0.44em;
			padding: 0 2.25em 0 0.75em;
			background-image: url('../../../assets/images/icons/icon-move.svg');
			background-size: 1.67em auto;
			background-position: right 0.7em center;
			transform-origin: right 0.5em center;
			@include button-blue();
			@include no-select();
			@include flex('flex-start', 'center');
			@include rotate(-7deg);
			p {
				text-transform: none;
			}
			&.double-height {
				height: 6em;
			}
		}

		&.prep-extinguishers {
			width: 12.56em;
			height: 12.56em;
			background-color: $white;
			border-radius: 0.56em;
			@include box-shadow(0, 0, 0.44em, 0, rgba($black, 0.3));
	
			&.item-1 {
				background-image: url('../../../assets/images/tasks/sort/prep-extinguishers/item-1.svg');
				background-size: 42.7% auto;
			}
			&.item-2 {
				background-image: url('../../../assets/images/tasks/sort/prep-extinguishers/item-2.svg');
				background-size: 81.3% auto;
			}
			&.item-3 {
				background-image: url('../../../assets/images/tasks/sort/prep-extinguishers/item-3.svg');
				background-size: 43.8% auto;
			}
			&.item-4 {
				background-image: url('../../../assets/images/tasks/sort/prep-extinguishers/item-4.svg');
				background-size: 42.7% auto;
			}	
		}

		&.prep-shelves {
			width: 8.6em;
			height: 8.6em;
			background-color: $white;
			border-radius: 0.56em;
			@include box-shadow(0, 0, 0.44em, 0, rgba($black, 0.3));

			&.item-1 {
				background-image: url('../../../assets/images/tasks/sort/prep-shelves/item-1.svg');
				background-size: 40% auto;
			}
			&.item-2 {
				background-image: url('../../../assets/images/tasks/sort/prep-shelves/item-2.svg');
				background-size: 45% auto;
			}
			&.item-3 {
				background-image: url('../../../assets/images/tasks/sort/prep-shelves/item-3.svg');
				background-size: 70% auto;
			}
			&.item-4 {
				background-image: url('../../../assets/images/tasks/sort/prep-shelves/item-4.svg');
				background-size: 70% auto;
			}
			&.item-5 {
				background-image: url('../../../assets/images/tasks/sort/prep-shelves/item-5.svg');
				background-size: 55% auto;
			}
			&.item-6 {
				background-image: url('../../../assets/images/tasks/sort/prep-shelves/item-6.svg');
				background-size: 90% auto;
			}
		}

		&.sales-shop-window {
			width: 8.67em;
			height: 8.67em;
			background-color: $white;
			border-radius: 0.56em;
			@include box-shadow(0, 0, 0.44em, 0, rgba($black, 0.3));

			&.item-1 {
				background-image: url('../../../assets/images/tasks/sort/sales-shop-window/item-1.svg');
				background-size: 3.8em auto;
			}
			&.item-2 {
				background-image: url('../../../assets/images/tasks/sort/sales-shop-window/item-2.svg');
				background-size: 3.8em auto;
			}
			&.item-3 {
				background-image: url('../../../assets/images/tasks/sort/sales-shop-window/item-3.svg');
				background-size: 3.8em auto;
			}
			&.item-4 {
				background-image: url('../../../assets/images/tasks/sort/sales-shop-window/item-4.svg');
				background-size: 6.9em auto;
			}
			&.item-5 {
				background-image: url('../../../assets/images/tasks/sort/sales-shop-window/item-5.svg');
				background-size: 6.3em auto;
			}
			&.item-6 {
				background-image: url('../../../assets/images/tasks/sort/sales-shop-window/item-6.svg');
				background-size: 6.3em auto;
			}
			&.item-7 {
				background-image: url('../../../assets/images/tasks/sort/sales-shop-window/item-7.svg');
				background-size: 4.1em auto;
			}
			&.item-8 {
				background-image: url('../../../assets/images/tasks/sort/sales-shop-window/item-8.svg');
				background-size: 4.1em auto;
			}
			&.item-9 {
				background-image: url('../../../assets/images/tasks/sort/sales-shop-window/item-9.svg');
				background-size: 4.1em auto;
			}
		}
	}
}