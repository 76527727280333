@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
@import '../../styles/buttons';

.Login {
	height: 100%;
	width: 100%;
	@include flex('center', 'center', 'column');
	
	.Login-logos {
		position: absolute;
		bottom: 4.5em;
		left: 4.5em;
	}
	.Login-welcome {
		position: absolute;
		top: 6.28em;
		span {
			font-size: 2.11em;
			color: $darkBlue;
			text-transform: uppercase;
			font-weight: 950;
		}
	}

	.Login-logoWrapper {
		position: absolute;
		top: 9.78em;
	}
	.Login-wrap {
		position: absolute;
		top: 21.78em;
		width: 100%;
		.Login-school,
		.Login-group {
			position: relative;
			width: 24.75em;
			height: 28.17em;
			margin: calc(3.14em/2);
		}
	
		@include flex('center', 'center');
	}

	.Login-backBtn {
		position: absolute;
		width: 10em;
		bottom: 1em;
		left: 1em;
	}
}