@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
@import '../../styles/buttons';
.GameDone {
	height: 100%;
	width: 100%;
	@include flex('center', 'center');
	.GameDone-logo {
		position: absolute;
		top: 2em;
		left: 3.3em;
		cursor: pointer;
	}

	.GameDone-content {
		position: relative;
		width: 24.72em;
		height: 20em;
		background-color: white;
		border-radius: 0.56em;
		padding: 2.64em;
		.GameDone-title {
			text-align: center;
			margin-bottom: 4.2em;
			span {
				color: $darkBlue;
				font-weight: 900;
				font-size: 1.67em;
				text-transform: uppercase;
			}
		}
		.GameDone-buttons {
			.GameDone-leaderboardBtn {
				margin-bottom: 1em;
			}
		}
	}

	.GameDone-leaderBoard {
		position: absolute;
		top: 6.81em;
		left: calc(50% - 31.056em);
		width: 62.11em;
		height: 49.27em;
		background: linear-gradient(to right, #0F4B98, #2C71CB 50%, #0F4B98 100%);
		@include box-shadow(0, 0.166em, 0.44em, 0, rgba($blueShadow, 0.4));
		padding-top: 0.72em;
		padding-left: 1.05em;
		border-radius: 0.55em;
		.GameDone-leaderBoardTitle {
			@include flex('center', 'center');
			span {
				font-size: 2.11em;
				font-weight: 900;
				color: white;
				text-transform: uppercase;
			}
			margin-bottom: 0.77em;
		}
	}

	.GameDone-backBtn2 {
		position: absolute;
		left: 2em;
		bottom: 2em;
		width: 14.44em;
	}
}
