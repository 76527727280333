@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
@import '../../styles/buttons';
.LandingPageGroup {
	height: 100%;
	width: 100%;
	@include flex('center', 'center', 'column');
	.LandingPageGroup-logoWrapper {
		position: absolute;
		top: 9.78em;
		@include flex('flex-start', 'center', 'column');
		.LandingPageGroup-schoolName {
			position: relative;
			text-align: center;
			margin-top: 0.5em;
			span {
				color: white;
				font-size: 3em;
				font-weight: 900;
				text-transform: uppercase;
			}
		}
	}
	.LandingPageGroup-welcome {
		position: absolute;
		top: 6.25em;
		span {
			font-size: 2.11em;
			color: $darkBlue;
			text-transform: uppercase;
			font-weight: 950;
		}
	}

	.LandingPageGroup-qrCodeWrap {
		position: relative;
		.LandingPageGroup-qrCodeText {
			span {
				font-size: 1.5em;
				color: white;
				text-transform: uppercase;
				font-weight: 950;
			}
		}
		.LandingPageGroup-qrCodeImage {
			height: 15em;
			margin-top: 3em;
			background-size: auto 15em;
			&.game1 {
				background-image: url('../../assets/images/game1-qr-code.png');
			}
			&.game2 {
				background-image: url('../../assets/images/game2-qr-code.png');
			}
		}
	}
	.LandingPageGroup-startBtn {
		position: absolute;
		top: 43.72em;
		width: 19.44em;
	}
	.LandingPageGroup-gameNotStarted {
		@include flex('center', 'center');
		position: absolute;
		background-color: $white;
		height: 4em;
		bottom: 13.25em;
		padding: 0.5em;
		border-radius: 0.55em;
		background-image: url(../../assets/images/icons/icon-info.svg);
		background-size: contain;
		background-origin: content-box;
		background-position: left 0.5em bottom 0;

		span {
			padding-left: 3.5em;
			padding-right: 0.5em;
			color: $text-dark;
			font-weight: bold;
			font-size: 1.2em;
		}
	}
	.LandingPageGroup-logoutBtn {
		position: absolute;
		top: 1em;
		left: 1em;
	}
	.LandingPageGroup-resetBtn {
		position: absolute;
		top: 4em;
		left: 1em;

	}
}
