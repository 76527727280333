@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
@import '../../../styles/buttons';
.PopupEditSchool {
	position: absolute;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	@include flex('center', 'center', 'column');
	background: rgba($blueDark, 0.5);
	backdrop-filter: blur(1px);

	.PopupEditSchool-window {
		position: relative;
		width: 60em;
		margin-top: 5.39em;
		border-radius: 0.56em;
		background-color: white;
		padding: 3.2em 2.5em 2.5em 2.5em;
		@include box-shadow(0, 0.15em, 0.67em, 0, rgba($black, 0.2));
		@include flex('', 'center', 'column');

		.PopupEditSchool-title {
			margin-bottom: 2.2em;
			span {
				font-size: 1.67em;
				color: $blueDark;
				font-weight: 900;
				text-transform: uppercase;
			}
		}

		.PopupEditSchool-form {
			@include flex('', 'center', 'column');
			.PopupEditSchool-input {
				width: 27em;
				margin-left: -8.67em;
				margin-bottom: 1.22em;
				@include flex('space-between', 'center', 'row');
				.PopupEditSchool-inputTitle {
					width: 10em;
					span {
						font-size: 1.33em;
						font-weight: 500;
						color: $blueDark;
					}
				}

				input {
					width: 18.33em;
					padding: 0.25em;
					padding-left: 0.55em;
					padding-right: 0.55em;
					border-style: solid;
					border-color: $cyan;
					border-radius: 0.44em;
					background-color: $blueLight;
				}
				.PopupEditSchool-scenarios {
					width: 18.33em;

					.PopupEditSchool-scenario {
						position: relative;
						padding-left: 2em;
						height: 1.5em;
						cursor: pointer;
						@include no-select();
						span {
							font-weight: 500;
							color: $blueDark;
						}
						&::after {
							content: '';
							width: 1.25em;
							height: 1.25em;
							position: absolute;
							left: 0;
							top: calc(50% - 0.7em);
							border: 0.2em solid $cyan;
						}
						&.selected {
							&::after {
								background-color: $blueDark;
							}
						}
					}
				}
			}

			.PopupEditSchool-submitButton {
				width: 18.33em;
			}
		}

		.PopupEditSchool-errorMessage {
			margin-top: 2em;
			span {
				color: red;
			}
		}
	}
}