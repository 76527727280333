@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
@import '../../../styles/buttons';
.OrderDndContainer {
	position: relative;
	width: 23.89em;
	height: 3em;
	border-radius: 0.44em;
	background-color: $blueLight;
	&.double-height {
		height: 6em;
	}
	&.hover {
		background-color: $blueGrey;
	}
	@include no-select();
}