@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
@import '../../../styles/buttons';

.InputSearch {
	position: relative;
	width: 100%;
	.InputSearch-schoolInput {
		width: 100%;
		height: 2.5em;
		padding-left: 0.5em;
		background-color: $blueLight;
		border-style: solid;
		border-color: $cyan;
		border-radius: 0.44em;
		border-width: 0.11em;
		color: $blueDark;
		margin-bottom: 0em;
	}

	.InputSearch-optionsWrapper {
		display: none;
		width: 100%;
		overflow-y: hidden;
		position: absolute;
		z-index: 3;
		background-color: $white;
		border-style: solid;
		border-color: $cyan;
		border-radius: 0.44em;
		border-width: 0.11em;

		&.isOpen {
			display: block;
			max-height: 5em;
		}

		.InputSearch-option {
			padding: 0.25em;
			padding-left: 0.5em;
			cursor: pointer;
			span {
				color: $darkBlue;
			}

			&:hover {
				background-color: darken($white, 10%);
			}

			&.focus {
				background-color: $blue;
				span {
					color: $white;
				}
			}
		}
	}
}

