@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
@import '../../../styles/buttons';
.Leaderboard {
	position: relative;
	color: black;

	.Leaderboard-header {
		width: 60em;
		height: 5.11em;
		background-color: $blueLight;
		border-radius: 0.56em 0.56em 0 0;
		@include flex('flex-start', 'center', row);
		background-image: url('../../../assets/images/icons/icon-trophy.svg');
		background-size: 3.9em 3.89em;
		background-position: center left 1.43em;

		&.game2 {
			background-color: $beige;
		}

		.Leaderboard-title {
			margin-left: 6.3em;
			span {
				font-size: 1.67em;
				font-weight: 900;
				color: $darkBlue;
				text-transform: uppercase;
			}
		}
		.Leaderboard-closeButton {
			position: absolute;
			width: 2.11em;
			height: 2.11em;
			background-color: $blueGrey;
			top: 0.81em;
			left: 56.67em;
			border-radius: 50%;
			background-image: url('../../../assets/images/icons/icon-cross2.svg');
			background-size: 1em 1em;
			background-position: center center;
			cursor: pointer;

			&:hover {
				background-color: darken($blueGrey, 10%);
			}

			&.game2 {
				background-color: $beigeDark;

				&:hover {
					background-color: darken($beigeDark, 10%);
				}
			}
		}
	}
	.Leaderboard-body {
		background-color: white;
		height: clac(44.16em - 5.11em);
		width: 60em;
		height: 39.06em;
		padding-left: 1.43em;
		padding-right: 1.2em;
		padding-top: 1.27em;
		border-radius: 0 0 0.56em 0.56em;
		.Leaderboard-row {
			width: 59.897em;
			@include flex('flex-start', 'center', row);
			height: 3.19em;
			border-radius: 0.22em;

			
			&:first-child {
				height: 1.22em;
				.Leaderboard-field {
					&.rank,
					&.name,
					&.points {
						span {
							text-transform: uppercase;
							font-size: 1em;
						}
					}
				}
				
			}

			&:not(:last-child) {
				margin-bottom: 0.28em;
			}

			&:not(:first-child) {
				.Leaderboard-field {
					&.rank,
					&.name,
					&.points {
						background-color: $blueIdle;
					
						&.game2 {
							background-color: rgba($beige, 0.5);
						}
					}
					&.points {
						padding-right: 1em;
						white-space: nowrap;	
						@include flex('flex-end', 'center');
					}
				}

			}

			&:not(:nth-child(n+5), :first-child) {
				.Leaderboard-field {
					&.name,
					&.points {
						background-color: $blueActive;
						
						&.game2 {
							background-color: $beige;
						}

						&.animated {
							@include colorBlink(0.5s, 0s, 3, $blueActive, $bluegradient);

							&.game2 {
								@include colorBlink(0.5s, 0s, 3, $beige, $beigeDark);
							}

							span {
								@include textColorBlink(0.5s, 0s, 3, $text-dark, $text-light);
								color: $text-light;
							}
						}
					}
				}
				&:nth-child(2) .Leaderboard-field.rank {
					background-color: $gold;
					&.animated {
						@include colorBlink(0.5s, 0s, 3, $gold, $bluegradient);

						&.game2 {
							@include colorBlink(0.5s, 0s, 3, $gold, $beigeDark);
						}

						span {
							@include textColorBlink(0.5s, 0s, 3, $text-dark, $text-light);
							color: $text-light;
						}
					}
				}
				&:nth-child(3) .Leaderboard-field.rank {
					background-color: $silver;
					&.animated {
						span {
							@include textColorBlink(0.5s, 0s, 3, $text-dark, $text-light);
							color: $text-light;
						}
						@include colorBlink(0.5s, 0s, 3, $silver, $bluegradient);
						&.game2 {
							@include colorBlink(0.5s, 0s, 3, $silver, $beigeDark);
						}
					}
				}
				&:nth-child(4) .Leaderboard-field.rank {
					background-color: $bronze;
					&.animated {
						@include colorBlink(0.5s, 0s, 3, $bronze, $bluegradient);
						&.game2 {
							@include colorBlink(0.5s, 0s, 3, $bronze, $beigeDark);
						}
						span {
							@include textColorBlink(0.5s, 0s, 3, $text-dark, $text-light);
							color: $text-light;
						}
					}
				}

				&:nth-child(2) .Leaderboard-field.name { 
					background-image: url('../../../assets/images/icons/icon-trophy-gold.svg');
					background-size: 2.58em 2.24em;
					background-position: center right 0em;
				}
				&:nth-child(3) .Leaderboard-field.name {
					background-image: url('../../../assets/images/icons/icon-trophy-silver.svg');
					background-size: 2.58em 2.24em;
					background-position: center right 0em;
				}
				&:nth-child(4) .Leaderboard-field.name { 
					background-image: url('../../../assets/images/icons/icon-trophy-bronze.svg');
					background-size: 2.58em 2.24em;
					background-position: center right 0em;}
			}

			.Leaderboard-field {
				@include flex('flex-start', 'center');
				height: 3.19em;
				span {
					font-size: 1.56em;
					font-weight: 900;
					color: $darkBlue;
				}

				&.rank {
					@include flex('center', 'center');
					width: 3.48em;
					margin-right: 0.34em;
					border-radius: 0.22em;

				}
				&.name {
					width: 47.82em;
					border-radius: 0.22em 0 0 0.22em;
				}
				&.points {
					width: 5.61em;
					border-radius: 0 0.22em 0.22em 0;
					padding-left: 1.61em;
				}

				.Leaderboard-pointsField {
					&.animated {
						@include scaleBounce(0.5s, 0s, 3);
					}
				}
			}

			
			
		}
	}
	&.open {
		width: 59.897em;
		height: 44.16em;
		.Leaderboard-body {
			@include box-shadow(0, 0.166em, 0.44em, 0, rgba($blueShadow, 0.4));
		}
	}
	&.done {
		width: 62.11em;
		height: 49.27em;
	}
}