@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
@import '../../../styles/buttons';
.Background {
	overflow: hidden;
	position: absolute;
	width: 100%;
	height: 100%;

	.Background-novo {
		width: 100%;
		height: 100%;
		background-size: cover;
		background-position: center bottom;

		&.it {
			background-image: url(../../../assets/images/backgrounds/novo-medical/it.png);
		}
		&.school {
			background-image: url(../../../assets/images/backgrounds/novo-medical/office.png);
		}
		&.research {
			background-image: url(../../../assets/images/backgrounds/novo-medical/research.png);
		}
		&.build {
			background-image: url(../../../assets/images/backgrounds/novo-medical/build.png);
		}
		&.prep {
			background-image: url(../../../assets/images/backgrounds/novo-medical/prep.png);
		}
		&.design {
			background-image: url(../../../assets/images/backgrounds/novo-medical/design.png);
		}
		&.production {
			background-image: url(../../../assets/images/backgrounds/novo-medical/production.png);
		}
		&.office,
		&.login {
			background-image: url(../../../assets/images/backgrounds/novo-medical/login.png);
		}
	}

	.Background-goodsellers {
		width: 100%;
		height: 100%;
		background-size: cover;
		background-position: center bottom;

		&.marketing {
			background-image: url(../../../assets/images/backgrounds/goodsellers/marketing.png);
		}
		&.it {
			background-image: url(../../../assets/images/backgrounds/goodsellers/it.png);
		}
		&.school {
			background-image: url(../../../assets/images/backgrounds/goodsellers/office.png);
		}
		&.management {
			background-image: url(../../../assets/images/backgrounds/goodsellers/management.png);
		}
		&.sales {
			background-image: url(../../../assets/images/backgrounds/goodsellers/sales.png);
		}
		&.office,
		&.login {
			background-image: url(../../../assets/images/backgrounds/goodsellers/login.png);
		}
		&.economy {
			background-image: url(../../../assets/images/backgrounds/goodsellers/economy.png);
		}
	}
}