@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
@import '../../../styles/buttons';
.OrderDnDItem {
	position: relative;
	width: 23.89em;
	height: 3em;
	border-radius: 0.44em;
	padding: 0 2.25em 0 0.75em;
	cursor: move;
	background-image: url('../../../assets/images/icons/icon-move.svg');
	background-size: 1.67em auto;
	background-position: right 0.7em center;	
	@include button-blue();	
	@include no-select();
	@include flex('flex-start', 'center');

	&.game2 {
		@include button-purple();
		span {
			text-transform: none;
		}
	}
	span {
		text-transform: none;
	}
	&.double-height {
		height: 6em;
	}
	&.disabled {
		cursor: not-allowed;
		background-image: none;
	}		
	&.correct {
		@include button-green();
		span {
			text-transform: none ;
		}
	}
	&.wrong {
		@include button-red();
		span {
			text-transform: none ;
		}
	}
	
}