@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
@import '../../styles/buttons';
.GameReady {
	position: relative;
	height: 100%;
	width: 100%;
	@include flex('flex-start', 'center', 'column');
	.GameReady-logo {
		position: absolute;
		top: 2em;
		left: 3.3em;
		cursor: pointer;
	}
	.GameReady-schoolName {
		position: relative;
		margin-top: 1.5em;
		span {
			color: white;
			font-size: 2.5em;
			font-weight: 900;
			text-transform: uppercase;
		}
	}
	.GameReady-title {
		margin-top: 8em;
		span {
			font-size: 1.67em;
			font-weight: 900;
			text-transform: uppercase;
		}
	}
	.GameReady-groups {
		margin-top: 3em;
		margin-left: 2em;
		@include flex('flex-start', 'center', 'row');
		flex-wrap: wrap;
		width: 94.22em;
		height: auto;
		max-height: 24.34em;
		overflow-y: overlay;
		@include custom-scrollbar(0.77em, $blueDarker, $blueGrey, 0.55em);

		.GameReady-group {
			width: 22.22em;
			height: 3.89em;
			margin-right: 1.11em;

			&:last-child, 
			&:nth-child(4n) {
				margin-right: 0em;
			}

			&:nth-child(n+5) {
				margin-top: 1.11em;
			}
			background-color: $blueLight;

			border-radius: 0.56em;
			@include opacity(0.6);
			.GameReady-groupName {
				display: none;
			}
			
			&.active {
				background-color: white;
				@include opacity(1);
				pointer-events: none;
				background-image: url('../../assets/images/icons/icon-group.svg');
				background-size: 2.11em 3.13em;
				background-position: top 0.32em left 1.19em;
				.GameReady-groupName {
					display: block;
					background-color: $blueLight;
					height: 2.78em;
					width: 17.29em;
					border-radius: 0.28em;
					@include flex('flex-start', 'center', 'row');
					margin-left: 4.21em;
					margin-top: 0.56em;
					&.game2 {
						background-color: $beige;
					}
				}
			}
		}	
	}
	.GameReady-startBtn {
		position: absolute;
		top: 48.59em;
		width: 19.44em;
		height: 3em;
	}
	.GameReady-audio {
		position: absolute;
		top: 55em;
		right: 2em;
	}

	.GameReady-backBtn {
		position: absolute;
		bottom: 2em;
		left: 2em;
		width: 14.44em;
		height: 3.66em;
	}
}
