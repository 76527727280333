@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
@import '../../../styles/buttons';
.SortDndContainer {
	position: relative;
	min-height: 7.67em;
	border-radius: 0.22em;
	background-color: $blueLight;
	padding: 0.5em;

	&.double-height {
		min-height: 12em;
	}

	&.hover {
		background-color: $blueGrey;
	}


	&.prep-extinguishers {
		background-color: transparent;
		border-radius: 0.56em;
		height: 100%;
		@include flex('center', 'center');
		flex-flow: row wrap;
		&.hover {
			background-color: transparent;
			border: 0.17em solid $blueGrey;
		}

		&.computer {
			background-image: url('../../../assets/images/tasks/sort/prep-extinguishers/fire-computer.svg');
			background-size: 8.8em auto;
		}
		&.pot {
			background-image: url('../../../assets/images/tasks/sort/prep-extinguishers/fire-pot.svg');
			background-size: 7.5em auto;
		}
		&.furniture {
			background-image: url('../../../assets/images/tasks/sort/prep-extinguishers/fire-furniture.svg');
			background-size: 12.5em auto;
		}
	}

	&.prep-shelves {
		border-radius: 0.56em;
		@include flex('space-around', 'center');
		background-color: transparent;
		min-height: auto;
		height: 7.25em;

		&.hover {
			background-color: transparent;
			border: 0.17em solid $blueGrey;
		}
	}

	&.sales-shop-window {
		height: 100%;
		background-color: transparent;
		border-radius: 0.56em;
		border: 0.17em solid transparent;
		padding: 0;
		@include flex('flex-start', 'center');
		&.hover {
			background-color: transparent;
			border: 0.17em solid $blueGrey;
		}
	}
}