@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
@import '../../../styles/buttons';
.Order {
	position: relative;
	width: 100%;
	height: 100%;
	padding: 1.67em 2.44em;
	overflow: auto;
	scroll-behavior: smooth;
	-webkit-overflow-scrolling: touch;
	@include hide-scrollbar();

	.Order-intro {
		margin-bottom: 1.67em;
		p {
			color: $darkBlue;
			font-size: 1.17em;
			line-height: 1.61;
			margin-bottom: 0;
			&:first-of-type {margin-top: 0;}
		}
	}

	.Order-items {
		position: relative;
		width: 100%;
		&.size-3 {height: calc(3 * 3em + 2 * 1em);}
		&.size-4 {height: calc(4 * 3em + 3 * 1em);}
		&.size-5 {height: calc(5 * 3em + 4 * 1em);}
		&.size-6 {height: calc(6 * 3em + 5 * 1em);}
		&.size-7 {height: calc(7 * 3em + 6 * 1em);}
		&.size-8 {height: calc(8 * 3em + 7 * 1em);}
		&.size-9 {height: calc(9 * 3em + 8 * 1em);}
		&.size-10 {height: calc(10 * 3em + 9 * 1em);}
		
		.Order-container {
			position: absolute;
			top: 0;
			height: 3em;
			@include flex('flex-start', 'center');

			.Order-containerNumber {
				width: 3em;
				height: 100%;
				background-color: $blueLight;
				border-radius: 0.44em;
				color: $darkBlue;
				margin-right: 0.9em;
				@include flex('center', 'center');
				span {
					@include no-select();
					font-size: 1.33em;
					font-weight: bold;
				}
			}
			&.pos-2 {top: 4em;}
			&.pos-3 {top: calc(2 * 4em);}
			&.pos-4 {top: calc(3 * 4em);}
			&.pos-5 {top: calc(4 * 4em);}
			&.pos-6 {top: calc(5 * 4em);}
			&.pos-7 {top: calc(6 * 4em);}
			&.pos-8 {top: calc(7 * 4em);}
			&.pos-9 {top: calc(8 * 4em);}
			&.pos-10 {top: calc(9 * 4em);}
			&.final {
				left: 0;
				width: 27.22em;
			}
			&.initial {
				left: calc(27.22em + 2.22em);
				width: 23.89em;
			}
		}
		.Order-item {
			position: absolute;
			top: 0;
			left: calc(3em + 0.5em);
			width: 23.89em;
			height: 3em;
			@include flex('center', 'center');
			@include no-select();
			&.pos-2 {top: 4em;}
			&.pos-3 {top: calc(2 * 4em);}
			&.pos-4 {top: calc(3 * 4em);}
			&.pos-5 {top: calc(4 * 4em);}
			&.pos-6 {top: calc(5 * 4em);}
			&.pos-7 {top: calc(6 * 4em);}
			&.pos-8 {top: calc(7 * 4em);}
			&.pos-9 {top: calc(8 * 4em);}
			&.pos-10 {top: calc(9 * 4em);}
			&.final {
				@include translate(0);
			}
			&.initial {
				@include translate(26em);
				width: 23.89em;
			}
		
			&.correct {
				background-color: greenyellow;
				cursor: not-allowed;
			}
			&.animate {
				@include transitionTransform(1s, ease-in-out, 0.5s);
			}
		}
	}
	.Order-doneBtn {
		position: absolute;
		bottom: 1.33em;
		left: calc(50% - 0.5 * 8.75em);
		width: 8.75em;
		&.size-10 {
			bottom: -6em;
			padding-bottom: 1em;
		}
	}


	&.double-height {
		.Order-items {
			&.size-3 {height: calc(3 * 6em + 2 * 1em);}
			&.size-4 {height: calc(4 * 6em + 3 * 1em);}
			&.size-5 {height: calc(5 * 6em + 4 * 1em);}
			&.size-6 {height: calc(6 * 6em + 5 * 1em);}
			&.size-7 {height: calc(7 * 6em + 6 * 1em);}
			&.size-8 {height: calc(8 * 6em + 7 * 1em);}
			&.size-9 {height: calc(9 * 6em + 8 * 1em);}
			&.size-10 {height: calc(10 * 6em + 9 * 1em);}
		}
		.Order-container { 
			height: 6em;
			&.pos-2 {top: 7em;}
			&.pos-3 {top: calc(2 * 7em);}
			&.pos-4 {top: calc(3 * 7em);}
			&.pos-5 {top: calc(4 * 7em);}
			&.pos-6 {top: calc(5 * 7em);}
			&.pos-7 {top: calc(6 * 7em);}
			&.pos-8 {top: calc(7 * 7em);}
			&.pos-9 {top: calc(8 * 7em);}
			&.pos-10 {top: calc(9 * 7em);}
		}
		.Order-item { 
			height: 6em;
			&.pos-2 {top: 7em;}
			&.pos-3 {top: calc(2 * 7em);}
			&.pos-4 {top: calc(3 * 7em);}
			&.pos-5 {top: calc(4 * 7em);}
			&.pos-6 {top: calc(5 * 7em);}
			&.pos-7 {top: calc(6 * 7em);}
			&.pos-8 {top: calc(7 * 7em);}
			&.pos-9 {top: calc(8 * 7em);}
			&.pos-10 {top: calc(9 * 7em);}
		}
	}
}