@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
@import '../../styles/buttons';
.LandingPageSchool {
	height: 100%;
	width: 100%;
	padding-top: 1.82em;
	@include flex('flex-start', 'center', 'column');
	.LandingPageSchool-logoWrapper {
		position: relative;
	}

	
	.LandingPageSchool-content {
		position: relative;
		width: 60em;
		background-color: white;
		border-radius: 0.56em;
		padding: 1.7em 0.8em 1.8em 1.8em;
		margin-top: 1.17em;
		@include flex('', 'center', 'column');
		@include box-shadow(0, 0.15em, 0.67em, 0, rgba($black, 0.2));

		span {
			color: $darkBlue;
		}

		.LandingPageSchool-schoolName {
			margin-bottom: 0.92em;
			padding-right: 1em;
			span {
				font-size: 1.7em;
				text-transform: uppercase;
				font-weight: bold;
			}
		}

		.LandingPageSchool-schoolStats {
			@include flex('center', '', 'row');
			gap: 1.7em;
			margin-bottom: 0.96em;
			padding-right: 1em;

			.LandingPageSchool-studentCount {
				width: 10em;
				text-align: right;
				span {
					font-size: 1.2em;
				}
			}
			.LandingPageSchool-playthroughCount {
				width: 10em;
				text-align: left;
				span {
					font-size: 1.2em;
				}
			}
		}

		.LandingPageSchool-createGameButton {
			padding-right: 1em;
			margin-bottom: 1.37em;
			span {
				color: $white;
			}
		}

		.LandingPageSchool-games {
			width: 100%;
			.LandingPageSchool-row {
				width: calc(100% - 1em);
				@include flex('space-between', 'center', 'row');
				.LandingPageSchool-cell {
					width: 14em;
					
					&.delete {
						width: 2em;
						margin: 0 1em;
					}
					&.center {
						text-align: center;
					}
				}
				&.header {
					padding: 0.9em;
					background-color: $blue;
					.LandingPageSchool-cell {
						span {
							color: $white;
							font-weight: 500;
						}
					}
				}
				&.body {
					padding: 0.5em 0.9em;
					border-bottom-style: solid;
					border-bottom-color: $blueGrey;
					border-bottom-width: 0.1em;
					.LandingPageSchool-cell {
						span {
							color: $darkBlue;
							text-transform: uppercase;
							font-size: 1.2em;
						}
						&.delete {
							background-image: url(../../assets/images/icons/icon-close.svg);
							background-position: center center;
							height: 2em;
							cursor: pointer;
						}
					}
				}
			}
			.LandingPageSchool-gamesWrap {
				max-height: 30em;
				overflow: auto;
				@include custom-scrollbar(0.77em, $blueDarker, $blueGrey, 0.55em);
			}
		}
	}

	.LandingPageSchool-buttonsWrapper {
		position: absolute;
		left: 1em;
		top: 9.75em;


		.LandingPageSchool-manualBtn {
			position: relative;
			width: 22em;
			margin-bottom: 1em;
		}
		.LandingPageSchool-logoutBtn {
			position: relative;
			width: 22em;
			margin-bottom: 1em;
		}
		.LandingPageSchool-adminBtn {
			position: relative;
		}
	}

	.LandingPageSchool-backBtn {
		position: absolute;
		width: 10em;
		bottom: 1em;
		left: 1em;
	}
}
