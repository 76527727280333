@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
@import '../../../styles/buttons';
.Logos {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	@include flex('space-between');
	z-index: 2;
	padding: 2em;

	.Logos-karrierindsatsen {
		background-image: url(../../../assets/images/logo-karrierindsatsen.svg);
		background-size: contain;
		width: 25.6em;
		height: 2.4em;
	}
	.Logos-cgl {
		background-image: url(../../../assets/images/logo-cgl.svg);
		background-size: contain;
		width: 9em;
		height: 1.8em;
	}
}