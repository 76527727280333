@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
@import '../../styles/buttons';

.AdminLogin {
	height: 100%;
	width: 100%;
	@include flex('center', 'center', 'column');
	
	.AdminLogin-wrap {
		position: absolute;
		top: 21.78em;
    width: 24.75em;
		height: 28.17em;
    background-color: white;
    border-radius: 0.5em;
		.AdminLogin-title {
      width: 100%;
      height: 5em;
      @include flex('flex-start', 'center');
      padding: 0 3.39em;
      background-color: $blueLight;
      border-top-left-radius: 0.5em;
      border-top-right-radius: 0.5em;
      span {
        font-size: 1.67em;
        font-weight: 900;
        color: $darkBlue;
        text-transform: uppercase;
      }
    }
    .AdminLogin-body {
      width: 100%;
		  height: calc(100% - 5em);
      border-top: 0.12em solid $cyan;
      border-bottom-left-radius: 0.5em;
      border-bottom-right-radius: 0.5em;
      padding: 1.45em  3.222em 0 3.222em;


      .AdminLogin-form {
        position: relative;
        margin-bottom: 0.9em;
        .AdminLogin-input {
          display: block;
          font-size: 1.17em;
          line-height: 1;
          width: 100%;
          height: 2.19em;
          padding: 0.66em;
          margin: auto;
          margin-bottom: 1.16em;
          margin-top: 1.16em;
          background-color: $blueLight;
          border-radius: 0.44em;
          border-width: 0.11em;
          border-style: solid;
          border-color: $cyan;
          color: $darkBlue;
          font-weight: normal;
          &::placeholder {
            color: rgba($text-dark, 0.5);
          }
        }
        .AdminLogin-pageBtn {
          color:  rgba($text-dark, 0.5);
          text-align: center;
          margin-bottom: 1.16em;
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }
        .AdminLogin-buttons {
          @include flex('space-between', 'center');
          > div { 
            margin: 0 0.5em;
            &:nth-child(1) {margin-left: 0;}
            &:nth-last-child(1) {margin-right: 0;}
          }			
          
        }
      }	

			
		.AdminLogin-errorMessage {
			height: 1.5em;
			margin-top: 1em;
			margin-bottom: 0.6em;
			padding: 0 1em;
			font-size: 1.11em;
			color: $text-dark;
			text-align: center;
		}
    }
	}
}