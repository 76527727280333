$pink: #D8016B;
$purple: #793D90;
$green: #7FB979;
$blue: #2C71CB;
$bluegradient: #0F4B98;
$cyan: #A4D1DF;
$yellow: #FFE49A;
$darkYellow: #FACA47;
$darkerYellow: #D8A90B;
$beige: #E5E2D8;
$beigeLight: #EDEBE2;
$beigeDark: #CDCAC5;
$beigeDark: #afa89d;

$purpleLight: #6E60BA;
$purple: #544896;
$purpleDark: #483C87;
$purpleDarker: #322963;

$black: #000000;
$white: #ffffff;
$offwhite: #F2F2F2;
$greyBlue: #7294AB;

$blueIdle: #E7F4F8;
$blueActive: #CEE9F0;
$blueLight: #D4EEF5;
$blueDark: #24363C;
$blueDarker: #082B58;
$blueSteel: #8EA2A8;
$blueWhite: #D4EEF5;
$blueGrey: #A2D1E0;
$blueHighlight: #BEE0EA;
$blueShadow: #0B2047;
$blueBorder: #2A4969;

$red: #DB2C2F;
$redDark: #A5181B;

$green: #009F49;
$greenDark: #0C6C30;

$text-dark: #24363C;
$text-light: #FCFAF0;

$darkBlue: #0B2047;

$gold: #DDA926;
$goldDark: #BF871E;
$silver: #90BAC1;
$bronze: #C9956E;