@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
@import '../../../styles/buttons';
.PopupVideo {
	position: absolute;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	z-index: 3;
	@include flex('center', 'center', 'column');
	background: rgba($blueDark, 0.5);
	backdrop-filter: blur(1px);

	.PopupVideo-window {
		position: relative;
		width: 100em;
		height: 55.56em;
		border-radius: 0.56em;
		background-color: white;
		padding: 3.2em 2.5em 2.5em 2.5em;
		@include box-shadow(0, 0.15em, 0.67em, 0, rgba($black, 0.2));
		@include flex('', 'center', 'column');

		.PopupVideo-title {
			margin-bottom: 2.2em;
			span {
				font-size: 1.67em;
				color: $blueDark;
				font-weight: 900;
				text-transform: uppercase;
			}
		}
		.PopupVideo-closeBtn {
			position: absolute;
			top: 0;
			right: 0;
			width: 4em;
			height: 4em;
			background-image: url('../../../assets/images/icons/icon-close.svg');
			background-size: 2em auto;
			cursor: pointer;
			&:hover {
				background-size: 2.2em auto;
			}
		}

		.PopupVideo-videoWrap {
			position: relative;
			width: 95.44em;
			height: calc(0.5625 * 95.44em);
			margin: 0 auto;
			background-color: $darkBlue;
			.PopupVideo-video {
				width: 100%;
				height: 100%;
				&.loading,
				&.ready,
				&.ended {
					@include opacity(0);
				}
			}
			.PopupVideo-videoPlayBtn {
				position: absolute;
				top: calc(50% - 4em);
				left: calc(50% - 4em);
				width: 8em;
				height: 8em;
				background-image: url('../../../assets/images/icons/icon-play.svg');
				cursor: pointer;	
				&.loading {cursor: default;}
				&.playing {
					display: none;
				}	
			}
		}
	}
}