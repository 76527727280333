@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
@import '../../../styles/buttons';
.PopupAssignment {
	position: absolute;
	z-index: 10;
	width: 100%;
	height: 100%;
	margin-top: -2em;

	.PopupAssignment-overlay {
		position: relative;
		background-color: rgba($darkBlue, 0.5);
		width: 100%;
		height: 100%;
	}
	.PopupAssignment-windowWrapper {
		position: absolute;
		top: 19.5em;
		left: 47em;
		width: 33.3em;
		@include flex('', '', 'column');

		.PopupAssignment-windowHeader {
			@include flex('', 'center', 'row');
			height: 5.1em;
			background-color: $blueWhite;
			border-top-left-radius: 0.55em;
			border-top-right-radius: 0.55em;
			padding-left: 1.4em;

			span {
				font-size: 1.67em;
				color: $text-dark;
				font-weight: 900;
			}

			.PopupAssignment-infoIcon {
				width: 3.44em;
				height: 3.89em;
				margin-right: 1.6em;
				background-image: url(../../../assets/images/icons/icon-info.svg);
				background-size: contain;
			}
		}

		.PopupAssignment-windowContent {
			@include flex('space-between', 'center', 'column');
			min-height: 11.67em;
			background-color: $white;
			padding: 1.67em;
			border-bottom-left-radius: 0.55em;
			border-bottom-right-radius: 0.55em;
			
			.PopupAssignment-text {
				padding-bottom: 1em;
				span {
					font-size: 1.16em;
					color: $text-dark;
				}
			}

			.PopupAssignment-button {
				color: $text-light;
			}
		}
	}

}